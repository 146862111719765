import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  icon_hover_1:{
    "&:hover":{
        "& g g":{
            [theme.breakpoints.up("md")]: {
                fill: theme.palette.primary.main
            },
        }
      }
  },
  icon_hover_2:{
    "&:hover":{
        "& g g g use":{
            [theme.breakpoints.up("md")]: {
                fill: theme.palette.primary.main
            },
        },
      }
  },
  icon_hover_3:{
    "&:hover":{
        "& g g g polygon":{
            [theme.breakpoints.up("md")]: {
                fill: theme.palette.primary.main
            },
        }

      }
  },
  icon_hover_4:{
    "&:hover":{
        "& g":{
            [theme.breakpoints.up("md")]: {
                fill: theme.palette.primary.main
            },
        }

      }
  }
}));

function SocialIcons(props) {
    const {network, className="", onClick=null} = props
    const classes = useStyles();
    function returnIcon(){
        switch(network){
            case "linkedin":
                return(
                    <svg className={classes.icon_hover_1} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g  id="Recursos" transform="translate(-446.000000, -1783.000000)" fill="#000000">
                                <path d="M470.000011,1783 L470.000011,1807.00001 L446,1807.00001 L446,1783 L470.000011,1783 Z M449.199962,1788.3 C449.199962,1787.14021 450.140148,1786.20002 451.299938,1786.20002 C452.459728,1786.20002 453.399913,1787.14021 453.399913,1788.3 C453.413585,1788.86103 453.196666,1789.40309 452.799816,1789.79988 C452.403087,1790.19673 451.860973,1790.41359 451.299938,1790.40004 C450.140148,1790.40004 449.199962,1789.45985 449.199962,1788.3 Z M466.500011,1803.50001 L466.500011,1797.30006 C466.500011,1794.20002 465.80006,1791.80006 462.200084,1791.80006 C460.820201,1791.72126 459.510631,1792.41456 458.80006,1793.60005 L458.80006,1792.00001 L455.400035,1792.00001 L455.400035,1803.50001 L459.000011,1803.50001 L459.000011,1797.80006 C459.000011,1796.30006 459.200084,1794.90004 461.099987,1794.90004 C462.900035,1794.90004 462.900035,1796.60005 462.900035,1797.90004 L462.900035,1803.50001 L466.500011,1803.50001 Z M453.199962,1803.50001 L453.199962,1792.00001 L449.599987,1792.00001 L449.599987,1803.50001 L453.199962,1803.50001 Z" id="ico_linkedin"/>
                            </g>
                        </g>
                    </svg>
                )
            case "facebook":
                return(
                    <svg className={classes.icon_hover_2} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <defs>
                            <rect id="path-1" x="0" y="0" width="24" height="24"/>
                        </defs>
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Recursos" transform="translate(-491.000000, -1783.000000)">
                                <g id="Group-2" transform="translate(491.000000, 1783.000000)">
                                    <mask id="mask-2" fill="white">
                                        <use href="#path-1"/>
                                    </mask>
                                    <use  id="Rectangle" fill="#000000" href="#path-1"/>
                                    <path d="M12,24 L12,15 L9,15 L9,11 L12,11 L12,8.413 C12,5.313 13.893,3.62483028 16.659,3.62483028 C17.5942421,3.62159828 18.5289706,3.6693362 19.459,3.768 L19.459,7.008 L17.536,7.008 C16.036,7.008 15.736,7.723 15.736,8.771 L15.736,11 L20.176,11 L19.176,15 L15.736,15 L15.736,24 L12,24 Z" id="Path" fill="#FFFFFF" />
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "twitter":
                return(
                    <svg className={classes.icon_hover_4} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g id="x" transform="translate(963 -12014)">
                            <rect n id="Rectángulo_2130" data-name="Rectángulo 2130" width="24" height="24" transform="translate(-963 12014)"/>
                            <path id="Icon_simple-x" data-name="Icon simple-x" d="M13.073,1.153h2.545L10.058,7.509,16.6,16.157H11.478L7.466,10.912,2.875,16.157H.328l5.948-6.8L0,1.154H5.253L8.879,5.948ZM12.18,14.634h1.41L4.486,2.6H2.973Z" transform="translate(-959.3 12017.345)" fill="#fff"/>
                        </g>
                    </svg>
                )
            case "email":
                return(
                    <svg className={classes.icon_hover_3} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Recursos" transform="translate(-582.000000, -1783.000000)">
                                <g id="Group-5" transform="translate(582.000000, 1783.000000)">
                                    <polygon id="Path"  fill="#000000" points="24 1.96220359e-05 -4.08562073e-14 1.96220358e-05 -4.08562073e-14 24.0000196 24 24.0000196"/>
                                    <path d="M19,5 L5,5 C4.4,5 4,5.4 4,6 L4,7.4 L12,11.9 L20,7.5 L20,6 C20,5.4 19.6,5 19,5 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"/>
                                    <path d="M11.5,13.9 L4,9.7 L4,18 C4,18.6 4.4,19 5,19 L19,19 C19.6,19 20,18.6 20,18 L20,9.7 L12.5,13.9 C12.22,14.04 11.78,14.04 11.5,13.9 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "whatsapp":
                return(
                    <svg className={classes.icon_hover_3} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Recursos" transform="translate(-627.000000, -1783.000000)">
                                <g id="Group-6" transform="translate(627.000000, 1783.000000)">
                                    <polygon id="Path-Copy" fill="#000000" points="24 1.96220359e-05 -4.08562073e-14 1.96220358e-05 -4.08562073e-14 24.0000196 24 24.0000196"/>
                                    <path d="M17.6,6.3 C16.1,4.8 14.2,4 12,4 C7.7,4 4.1,7.6 4.1,11.9 C4.1,13.3 4.5,14.7 5.2,15.9 L4,20 L8.2,18.9 C9.4,19.5 10.7,19.9 12,19.9 L12,19.9 L12,19.9 C16.4,19.9 19.9,16.3 19.9,12 C20,9.8 19.1,7.8 17.6,6.3 Z M15.9,14.8 C15.7,15.3 14.9,15.7 14.6,15.7 C14.3,15.8 13.3,15.7 12.2,15.2 C10.2,14.3 8.9,12.3 8.8,12.2 C8.7,12.1 8,11.1 8,10.2 C8,9.3 8.5,8.7 8.7,8.5 C9.1,8.1 9.9,8.2 10.1,8.6 C10.2,9 10.6,10 10.7,10.1 C10.8,10.2 10.8,10.6 10.5,10.8 C10.4,10.8 9.9,11.2 10.1,11.5 C10.2,11.7 10.6,12.3 11.2,12.9 C12,13.6 12.6,13.8 12.8,13.9 C13,14 13.1,14 13.2,13.8 C13.3,13.7 13.7,13.2 13.8,13 C13.9,12.8 14.1,12.8 14.2,12.9 C14.3,13 15.4,13.4 15.6,13.5 C15.8,13.7 16,13.7 16,13.8 C16.1,13.9 16.1,14.3 15.9,14.8 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "instagram":
                return(
                    <svg className={classes.icon_hover_3} xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="Recursos" transform="translate(-250.000000, -1858.000000)">
                                <g id="ico_instagram" transform="translate(250.000000, 1858.000000)">
                                    <polygon id="Path" fill="#000" points="24 1.96220357e-05 -4.08562073e-14 1.96220356e-05 -4.08562073e-14 24.0000196 24 24.0000196"/>
                                    <g id="logo-instagram" transform="translate(4.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <circle id="Oval" cx="12.145" cy="3.892" r="1"/>
                                        <path d="M8,12 C5.794,12 4,10.206 4,8 C4,5.794 5.794,4 8,4 C10.206,4 12,5.794 12,8 C12,10.206 10.206,12 8,12 Z M8,6 C6.897,6 6,6.897 6,8 C6,9.103 6.897,10 8,10 C9.103,10 10,9.103 10,8 C10,6.897 9.103,6 8,6 Z" id="Shape"/>
                                        <path d="M12,16 L4,16 C1.944,16 0,14.056 0,12 L0,4 C0,1.944 1.944,0 4,0 L12,0 C14.056,0 16,1.944 16,4 L16,12 C16,14.056 14.056,16 12,16 Z M4,2 C3.065,2 2,3.065 2,4 L2,12 C2,12.953 3.047,14 4,14 L12,14 C12.935,14 14,12.935 14,12 L14,4 C14,3.065 12.935,2 12,2 L4,2 Z" id="Shape"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "youtube":
                return(
                    <svg className={classes.icon_hover_4} xmlns="http://www.w3.org/2000/svg" width="24" height="24"><g fillRule="evenodd" clip-rule="evenodd"><path d="M0 0v24h24V0zm20.017 15.928c-.203.883-.926 1.536-1.796 1.633-2.06.23-4.145.23-6.222.23-2.076 0-4.161 0-6.221-.23-.87-.097-1.593-.75-1.796-1.633-.29-1.258-.29-2.632-.29-3.928 0-1.296.004-2.67.293-3.927.203-.884.926-1.536 1.795-1.633 2.06-.23 4.146-.231 6.223-.23 2.076-.001 4.16 0 6.221.23.87.097 1.593.749 1.796 1.633.29 1.258.288 2.631.288 3.927 0 1.296-.001 2.67-.29 3.928z"/><path d="M9.856 14.58l5.282-2.74-5.282-2.755z"/></g></svg>
                )

        }
    }
  return(
      <div className={className} onClick={onClick}>
        {returnIcon()}
      </div>
  );
}

export default SocialIcons;