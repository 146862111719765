import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Layout from "../components/basicLayout";
import { useTranslation } from "react-i18next";
import CurriculumIcon from "../assets/icons/curriculumIcon";
import PriorityLow from "../assets/icons/priorityLow";
import AntSwitch from "../components/antSwitch";
import EditIcon from "../assets/icons/editIcon";
import ButtonOutlined from "../components/buttonOutlined";
import {
  returnLangId,
  formatToLocalDate,
  renderSwitchDigitalProfile,
  validateFile,
  returnMimeData,
  base64ToArrayBuffer,
  getYear,
  getProvincias,
  getNationalityOptions,
  getDiscapacityOptions,
  getNivelesEstudio,
  getAreasEstudio,
  getIdiomasList,
  getNivelesIdioma,
  getPermisosConduccion,
  getSalaryRange,
  getTiposCambioResidencia,
  getTiposDesplazamientos,
  getContractTypes,
  handleGetMaestro,
} from "../utils/functions";
import { indicadorCv } from "../utils/enums";
import {
  getDatosPersonales,
  getFormations,
  getLanguages,
  getWorkExperience,
  getCandidateKnowledge,
  addCandidateKnowledge,
  deleteCandidateKnowledge,
  getCandidateDocuments,
  uploadCandidateDocuments,
  deleteCandidateDocuments,
  downloadDocumentInform,
  updateIndicadoresCvCandidato,
  getOtrosDatos,
  deleteProfileCandidate,
} from "../api/candidato";
import { tiposDocumentos } from "../utils/enums";
import i18next from "../i18n.js";
import { withSnackbar } from "notistack";
import ProfilePicture from "../redux/containers/profilePicture";
import PersonalData from "../assets/icons/personalData";
import OtherData from "../assets/icons/otherData";
import YourCV from "../assets/icons/yourCV";
import YourExperience from "../assets/icons/yourExperience";
import YourFormation from "../assets/icons/yourFormation";
import YourKnowledge from "../assets/icons/yourKnowledge";
import YourLanguages from "../assets/icons/yourLanguages";
import Download from "../assets/icons/download";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { getKnowledge, getPlace } from "../api/maestro";
import TrashRed from "../assets/icons/trashRed";
import DownloadDocument from "../assets/icons/downloadDocument";
import AlertDialog from "../components/alertDialog";
import Snackbar from "../components/customSnackBar";
import { AuthService } from "../auth/AuthService";
import CandidatePhoto from "../assets/icons/candidatePhoto";
import { normalizeString } from "../utils/functions";
import AutocompleteWithChips from "../redux/containers/autocompleteWithChips";
import { ID_APLICACION_GESTOR } from "../constants";
import useMaestros from "../Hooks/useMaestros";
const auth = new AuthService();

const nationalityOptions = getNationalityOptions(i18next.languages[0]);
const discapacityOptions = getDiscapacityOptions(i18next.languages[0]);
const provinces = getProvincias(i18next.languages[0]);
const niveles_de_estudio = getNivelesEstudio(i18next.languages[0]);
const areas_estudio = getAreasEstudio(i18next.languages[0]);
const idiomas_list = getIdiomasList(i18next.languages[0]);
const niveles_idioma = getNivelesIdioma(i18next.languages[0]);
const permisos_de_conduccion = getPermisosConduccion(i18next.languages[0]);
const rangos_salarios = getSalaryRange(i18next.languages[0]);
const tipos_cambio_residencia = getTiposCambioResidencia(i18next.languages[0]);
const tipos_desplazamientos = getTiposDesplazamientos(i18next.languages[0]);
const tipos_Contratos = getContractTypes(i18next.languages[0]);


const useStyles = makeStyles((theme) => ({
  principal_container: {
    gridArea: "body_container",
    display: "grid",
    gridTemplateColumns: "30% 65%",
    columnGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
    },
  },
  col1: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  backdrop: {
    zIndex: 500,
    color: "#fff",
  },
  col2: {
    marginTop: "100px",
    padding: 20,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "60px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
    maxWidth: "100%",
  },
  data_sections_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "25px",
  },
  summary_icon_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  title_text: {
    fontSize: 36,
    fontWeight: 900,
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  candidate_photo_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 127,
    width: 127,
    borderRadius: 6,
    background: "linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
  },
  photo: {
    width: "100%",
    height: "100%",
    borderRadius: 6,
    objectFit: "cover",
  },
  section_border: {
    height: 1,
    backgroundColor: "#EFEFEF",
  },
  data_section: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "20px",
  },
  data_section_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  data_section_header_customize: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  data_section_icon_and_title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  data_section_icon_and_title_customize: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px",
    alignItems: "baseline",
  },
  data_section_title: {
    fontSize: 22,
    fontWeight: 900,
    marginLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  data_section_title_customize: {
    fontSize: 22,
    fontWeight: 900,
    marginTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  data_section_linkbutton: {
    color: "#DA291C",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "underline",
    cursor: "pointer",
  },
  personal_data_section_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "20px",
  },
  fullname: {
    color: "rgba(0,0,0,0.87)",
    fontSize: 18,
    fontWeight: 900,
    marginBottom: "9px",
    wordBreak: "break-word",

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  personal_data_inner_sections: {
    fontSize: 14,
    fontWeight: 600,
    color: "rgba(0,0,0,0.87)",
    wordBreak: "break-all",
    overflowWrap: "break-word",
  },
  see_notifications_button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 14,
    color: "#DA291C",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  rotate_icon: {
    "& svg": {
      transform: "rotate(180deg)",
    },
  },
  no_data_yet_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  custom_button_size: {
    width: 251,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  delete_profile_linkbutton: {
    fontSize: 12,
    textDecoration: "underline",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center",
    },
  },
  digital_profiles_sections: {
    display: "flex",
    flexDirection: "row",
  },
  digital_profiles_button: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
    "& + &": {
      marginLeft: "5px",
    },
  },
  candidate_last_update: {
    display: "inline-block",
    color: "rgba(0,0,0,0.87)",
    fontSize: 12,
    fontWeight: 500,
  },
  name_and_date_grid: {
    display: "flex",
    flexDirection: "column",
  },
  grids_formaciones: {
    display: "flex",
    flexDirection: "column",
  },
  formacion_title_grid: {
    display: "flex",
    flexDirection: "column",
    "& + &": {
      marginTop: "60px",
    },
  },
  formacion_section_title: {
    fontSize: 16,
    fontWeight: 900,
    marginBottom: "20px",
  },
  your_data_grid: {
    display: "grid",
    gridTemplateColumns: ".5fr .5fr",
    columnGap: "24px",
    rowGap: "24px",
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gridAutoRows: "min-content",
      rowGap: "19px",
    },
  },
  your_data_inner_container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    rowGap: "5px",
  },
  formacion_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formacion_title: {
    fontSize: 14,
    fontWeight: 900,
  },
  edit_your_single_data: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  single_language_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 16,
    fontWeight: 900,
  },
  languages_inner_grid: {
    fontSize: 14,
    display: "grid",
    gridTemplateColumns: ".35fr .65fr",
    rowGap: "3px",
  },
  certificate_inner_list: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    overflow: "hidden",
  },
  chip: {
    backgroundColor: "#EFEFEF",
    borderRadius: "15px",
    height: "33px",
    padding: "7px 15px 7px 15px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "97%",
    float: "left",
    margin: "5px 10px 5px 0",
    fontSize: "14px",
    lineHeight: "20px",
  },
  container_conocimientos: {
    overflow: "hidden",
    paddingBottom: "20px",
    maxWidth: "100%",
  },
  experience_container: {
  },
  experience_dates: {
    color: "#949494",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "25px",
    display: "inline-flex",
    marginRight: "4px",
  },
  experience_details: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  experience: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  experience_functions: {
    display: "inline",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: "7px",
    marginLeft: "7px",
  },
  experience_separator: {
    backgroundColor: theme.palette.primary.main,
    width: "4px",
    height: "4px",
    borderRadius: "4px",
    display: "inline-flex",
  },
  experience_edit_button: {
    cursor: "pointer",
  },
  experience_title: {
    fontSize: "16px",
    lineHeight: "21px",
    fontWeight: 900,
    marginBottom: "15px",
  },
  experience_title_container: {
    display: "flex",
    justifyContent: "space-between",
  },
  referencia: {
    marginTop: "10px",
    lineHeight: "22px",
  },
  referencia_nombre: {
    fontWeight: 900,
  },
  referencia_email: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },

  see_all_otros_datos_style: {
    width: "100px",
    fontSize: 14,
    fontWeight: 900,
    display: "flex",
    flexDirection: "row",
    color: "#DA291C",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  cv_row_style: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    wordBreak: "break-all",
  },
  cv_buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
  },
  clickable_style: {
    cursor: "pointer",
    "& + &": {
      marginLeft: "17px",
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  hidden_input: {
    display: "none",
  },
  container_experience: {
    overflow: "hidden",
    paddingBottom: "25px",
    paddingTop: 0,
  },
  experience_ind: {
    float: "left",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "98%",
    paddingBottom: "5px",
    paddingTop: "5px",
  },
  certificate: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
    "& + &": {
      marginTop: "3px",
    },
  },
  otros_datos_row: {
    listStylePosition: "inside",
  },
  container_knowledge: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
    gridTemplateAreas: '"select selectFilled" "chips chips"',
    [theme.breakpoints.down("sm")]: {
      gridTemplateRows: "auto auto",
      gridTemplateAreas: '"select" "chips"',
    },
  },
  no_Space: {
    display: "none"
  },
  email: (props) => ({
    overflow: "hidden",
    whiteSpace: props.email < 95 ? "break-spaces" : "nowrap",
    textOverflow: "ellipsis",
    width: props.email < 95 ? "auto" : "290px",
  }),
}));

function Curriculum(props) {
  const { t } = useTranslation();
  const maestros = useMaestros();

  const [refPic, setRefPic] = useState(null);
  const [datosPersonalesLoaded, setDatosPersonalesLoaded] = useState(false);
  const [formacionesLoaded, setFormacionesLoaded] = useState(false);
  const [idiomasLoaded, setIdiomasLoaded] = useState(false);
  const [experienciaLaboralLoaded, setExperienciaLaboralLoaded] =
    useState(false);
  const [loadingKnowledgesCandidato, setLoadingKnowledgesCandidato] =
    useState(false);
  const [otrosDatosLoaded, setOtrosDatosLoaded] = useState(false);

  const { state, actions, locations, countries} = props;

  const [datosPersonales, setDatosPersonales] = useState(null);
  const [nombre, setNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [presentacion, setPresentacion] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [tipoIdentificacion, setTipoIdentificacion] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [prefijo, setPrefijo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [prefijoOtroTelefono, setPrefijoOtroTelefono] = useState("");
  const [otroTelefono, setOtroTelefono] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [provincia, setProvincia] = useState("");
  const [discapacidad, setDiscapacidad] = useState("");
  const [idFotografia, setIdFotografia] = useState(null);
  const [fechaModificacion, setFechaModificacion] = useState("");
  const [perfilesDigitales, setPerfilesDigitales] = useState("");
  const [noTieneExpLaboral, setNoTieneExpLaboral] = useState(false);
  const [noTieneFormacion, setNoTieneFormacion] = useState(false);
  const [noTieneIdiomas, setNoTieneIdiomas] = useState(false);
  const [noTieneConocimientos, setNoTieneConocimientos] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [isSwitchDisable, setIsSwitchDisable] = useState(false);

  const [experience, setExperience] = useState([]);

  const [cvFile, setCvFile] = useState(null);
  const [isCvFileLoading, setIsCvFileLoading] = useState(false);
  const [isCvActionLoading, setIsCvActionLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [snackbarText, setSnackbarText] = useState(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);

  const [otherData, setOtherData] = useState(null);

  const [showAllOtrosDatos, setShowAllOtrosDatos] = useState(false);
  const [seeAllPersonalData, setSeeAllPersonalData] = useState(false);
  const [showAllExperiences, setShowAllExperiences] = useState(false);
  const [showAllCertificaciones, setShowAllCertificaciones] = useState(false);
  const [showAllFormacionesRegladas, setShowAllFormacionesRegladas] =
    useState(false);
  const [showAllIdiomas, setShowAllIdiomas] = useState(false);
  const [identificationTypes,setIdentificationTypes] = useState([]);

  const [allKnowledges, setAllKnowledges] = useState([]);
  const [candidateKnowledges, setCandidateKnowledges] = useState(null);
  const [cvLoaded, setCvLoaded] = useState(false);

  const theme = useTheme();
  const matchesMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const inputRef = useRef(null);

  const [formaciones, setFormaciones] = useState(null);
  const [idiomas, setIdiomas] = useState(null);

  const tempRef = useRef(null);
  const innerBody = useRef(null);

  const [knowledgeError, setKnowledgeError] = useState(null);
  const [typeDialog, setTypeDialog] = useState("deleteDoc");
  const [employmentsOptions, setEmploymentsOptions] = useState(null);
  const [loadingPic, setLoadingPic] = useState(false);
  const [refPicture, setRefPicture] = useState(null);
  const [tipoHorarioOptions, setTipoHorarioOptions] = useState([]);

  const classes = useStyles({ email: correoElectronico?.length });

  const NIVEL_CERTIFICACIONES = 15;

  useEffect(() => {
    handleGetMaestro(tipoHorarioOptions,setTipoHorarioOptions,maestros.getTipoHorario());
  }, [tipoHorarioOptions]);

  useEffect(() => {
    handleGetMaestro(identificationTypes,setIdentificationTypes,maestros.getTipoIdentificacion());
  }, [identificationTypes]);

  useEffect(() => {
    if (!refPicture && idFotografia) {
      getProfilePic();
    }
  }, [idFotografia]);

  useEffect(() => {
    if (!datosPersonales) {
      handleDatosPersonales();
    } else {
      handleSetDatosPersonales();
    }
    handleGetExperienciaLaboral();
  }, [datosPersonales]);

  useEffect(() => {
    if (!formaciones) {
      handleFormaciones();
    }
  }, [formaciones]);

  useEffect(() => {
    handleGetCandidateKnowledge();
    handleGetKnowledges();
    handleGetPlaces();
  }, []);

  useEffect(() => {
    if (!idiomas) {
      handleIdiomas();
    }
  }, [idiomas]);

  useEffect(() => {
    if (state) {
      if (state.languageData) {
        actions.clearLanguageToEditAction();
      }
      if (state.formationData) {
        actions.clearFormationToEditAction();
      }
    }
  }, []);

  useEffect(() => {
    handleGetDocuments();
    handleGetOtrosDatos();
  }, []);

  async function handleGetPlaces() {
    const response = await getPlace(returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      const result = await response.json();
      setEmploymentsOptions(result.puestos);
    } else {
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("work_experience.get_places_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("work_experience.get_place_error"), {
            variant: "error",
          });
          break;
      }
    }
  }


  async function handleGetDocuments() {
    const response = await getCandidateDocuments(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setCvLoaded(true);
      const result = await response.json();
      var cv = result.find(
        (file) =>
          Number(file.idTipoDocumento) ===
          Number(tiposDocumentos.CURRICULUM_VITAE)
      );
      setCvFile(cv);
    } else {
      setCvLoaded(true);
      props.enqueueSnackbar(t("curriculum.cv_error"), {
        variant: "error",
      });
      setCvFile(null);
    }
  }

  function handleSelectFile(files) {
    if (files[0]) {
      if (validateFile(files[0]) && files[0].name.length <= 80 && /\./.test(files[0].name)) {
        setIsCvFileLoading(true);
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
          const replacement = ".";
          handleSaveDocument({
            nombre: files[0].name.replace(/.([^.]*)$/, replacement + "$1"),
            fichero: reader.result.substring(reader.result.indexOf(",") + 1),
          });
        };
      } else {
        if (files[0].size === 0) {
          props.enqueueSnackbar(t("error_docs.error_not_valid2"), {
            variant: "error",
          });
        } else if (files[0].name.length > 80) {
          props.enqueueSnackbar(t("error_docs.error_not_valid3"), {
            variant: "error",
          });
        } else if (!/\./.test(files[0].name)) {
          props.enqueueSnackbar(t("error_docs.error_not_valid4"), {
            variant: "error",
          });
        } else if (files[0].size > 20971520) {
          props.enqueueSnackbar(t("error_docs.error_not_valid5"), {
            variant: "error",
          });
        } else {
          props.enqueueSnackbar(t("error_docs.error_not_valid"), {
            variant: "error",
          });
        }
      }
    }
  }

  async function handleSaveDocument(file) {
    let response = await uploadCandidateDocuments(
      { idTipoDocumento: tiposDocumentos.CURRICULUM_VITAE, documentos: [file] },
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      const result = await response.json();
      const uploadedCv = result
        ? {
            idDocumento: result.documentos[0].idDocumento,
            idTipoDocumento: result.idTipoDocumento,
            nombre: result.documentos[0].nombre,
          }
        : null;
      setCvFile(uploadedCv);
    } else {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("curriculum.error_bad_request_update_documents"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(
            t("curriculum.error_not_found_update_documents"),
            {
              variant: "error",
            }
          );
          break;
        default:
          props.enqueueSnackbar(t("curriculum.update_error"), {
            variant: "error",
          });
          break;
      }
    }
    setIsCvFileLoading(false);
  }

  async function handleDeleteDocument(document) {
    setIsCvFileLoading(true);
    let response = await deleteCandidateDocuments(
      document.idDocumento,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setSnackbarText(t("curriculum.succesful_delete"));
      setIsSnackBarOpen(true);
      setCvFile(null);
    } else {
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("curriculum.error_bad_request_delete_documents"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(
            t("curriculum.error_not_found_delete_documents"),
            {
              variant: "error",
            }
          );
          break;
        default:
          props.enqueueSnackbar(t("curriculum.delete_error"), {
            variant: "error",
          });
          break;
      }
    }
    setIsCvFileLoading(false);
  }

  async function handleDownloadDocument() {
    setIsCvActionLoading(true);
    let response = await downloadDocumentInform(
      cvFile.idDocumento,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      const result = await response.json();

      const url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(result)], {
          type: returnMimeData(cvFile.nombre),
        })
      );
      let fileLink = document.createElement("a");
      fileLink.href = url;
      fileLink.download = cvFile.nombre;
      fileLink.click();
    } else {
      props.enqueueSnackbar(t("curriculum.error_download"), {
        variant: "error",
      });
    }
    setIsCvActionLoading(false);
  }

  async function handleDatosPersonales() {
    const response = await getDatosPersonales(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      setDatosPersonalesLoaded(true);
      const result = await response.json();
      setDatosPersonales(result);
    } else {
      setDatosPersonalesLoaded(true);
      props.enqueueSnackbar(t("curriculum.personal_data_error"), {
        variant: "error",
      });
      setDatosPersonales(null);
    }
  }

  async function handleFormaciones() {
    const response = await getFormations(returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      setFormacionesLoaded(true);
      const result = await response.json();
      setFormaciones(result);
    } else {
      setFormacionesLoaded(true);
      props.enqueueSnackbar(t("curriculum.formations_error"), {
        variant: "error",
      });
      setFormaciones(null);
    }
  }

  async function handleIdiomas() {
    const response = await getLanguages(returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      setIdiomasLoaded(true);
      const result = await response.json();
      setIdiomas(result);
    } else {
      setIdiomasLoaded(true);
      props.enqueueSnackbar(t("curriculum.languages_error"), {
        variant: "error",
      });
      setIdiomas(null);
    }
  }

  async function handleGetExperienciaLaboral() {
    const response = await getWorkExperience(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      const result = await response.json();
      setExperience(result.sort(orderExperienceCandidate));
      actions.setCandidateWorkExperience(result);
      setExperienciaLaboralLoaded(true);
    } else {
      setExperienciaLaboralLoaded(true);
      switch (response.status) {
        case 400:
          props.enqueueSnackbar(
            t("curriculum.get_work_experience_bad_request"),
            {
              variant: "error",
            }
          );
          break;
        case 404:
          props.enqueueSnackbar(t("curriculum.get_work_experience_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("curriculum.get_work_experience_error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  async function handleGetOtrosDatos() {
    const response = await getOtrosDatos(returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      const result = await response.json();
      setOtherData(result);
      setOtrosDatosLoaded(true);
    } else {
      setOtrosDatosLoaded(true);
      props.enqueueSnackbar(t("other_data.error_getting_data"), {
        variant: "error",
      });
      setOtherData(null);
    }
  }

  function getTipoDisponibilidadViajar() {
    return otherData.tipoDisponibilidadViajar
      ? tipos_desplazamientos.find(
          (desplazamientos) =>
            Number(desplazamientos.id) === otherData.tipoDisponibilidadViajar
        )
      : { name: "Sin especificar" };
  }

  function getTipoDisponibilidadCambioResidencia() {
    return otherData.tipoDisponibilidadCambioResidencia
      ? tipos_cambio_residencia.find(
          (cambio_residencia) =>
            Number(cambio_residencia.id) ===
            otherData.tipoDisponibilidadCambioResidencia
        )
      : tipos_cambio_residencia.find(
          (cambio_residencia) => Number(cambio_residencia.id) === 1
        );
  }

  function getSalarioMinimo() {
    return otherData.salarioMinimo
      ? rangos_salarios.find(
          (rango) => Number(rango.id) === otherData.salarioMinimo
        )
      : null;
  }

  function getSalarioDeseado() {
    return otherData.salarioDeseado
      ? rangos_salarios.find(
          (rango) => Number(rango.id) === otherData.salarioDeseado
        )
      : null;
  }

  function getTiposCarnetConducir() {
    return otherData.tiposCarnetConducir
      ? permisos_de_conduccion.filter((permiso) =>
          otherData.tiposCarnetConducir.includes(Number(permiso.id))
        )
      : [];
  }

  function getTiposHorario() {
    return otherData.tiposHorario
      ? tipoHorarioOptions.filter((jornada) =>
          otherData.tiposHorario.includes(Number(jornada.id))
        )
      : [];
  }

  function getPuestoDeseadoFavorito() {
    return otherData.puestosDeseados && employmentsOptions
      ? employmentsOptions.find(
          (e) =>
            otherData.puestosDeseados?.find((p) => p.esPrincipal)?.idPuesto ===
            e.id
        )
      : "";
  }
  
  function getTiposContrato() {
    return otherData.tiposContrato
      ? tipos_Contratos.filter((c) =>
          otherData.tiposContrato.includes(Number(c.id))
        )
      : [];
  }

  async function handleGetKnowledges() {
    const response = await getKnowledge();
    if (response.status === 200) {
      const result = await response.json();
      setAllKnowledges(result.conocimientos);
    } else {
      if (response.status !== 404) {
        setKnowledgeError(t("curriculum.get_knowledge_error"));
      }
    }
  }

  async function handleGetCandidateKnowledge() {
    const response = await getCandidateKnowledge(
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      const result = await response.json();
      setCandidateKnowledges(
        result.map((r) => {
          return {
            id: r.idConocimiento,
            nombre: r.nombre,
          };
        })
      );
      setLoadingKnowledgesCandidato(false);
    } else {
      setLoadingKnowledgesCandidato(false);
      setCandidateKnowledges(null);
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("curriculum.candidate_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("curriculum.get_candidate_knowledge_error"), {
            variant: "error",
          });
          break;
      }
    }
  }

  function setterKnowledgeOptions(knowledge) {
    const newKnowledge = knowledge[knowledge.length - 1];
    if (typeof newKnowledge === "string") {
      const foundKnowledge = allKnowledges.find(
        (knowledge) =>
          knowledge?.nombre?.toUpperCase() === newKnowledge.toUpperCase()
      );
      if (foundKnowledge) {
        knowledge.pop();
      }
    }
    setAllKnowledges(knowledge);
  }

  function setterKnowledge(knowledge) {
    const newKnowledge = knowledge[knowledge.length - 1];
    if (typeof newKnowledge === "string") {
      const foundKnowledge = allKnowledges.find(
        (knowledge) =>
          knowledge?.nombre?.toUpperCase() === newKnowledge.toUpperCase()
      );
      if (foundKnowledge) {
        const index = candidateKnowledges.findIndex(
          (knowledge) =>
            knowledge?.nombre?.toUpperCase() === newKnowledge.toUpperCase()
        );
        if (index < knowledge.length - 1) {
          knowledge.pop();
        } else {
          knowledge[knowledge.length - 1] = foundKnowledge;
        }
      }
    }
    setCandidateKnowledges(knowledge);
  }

  async function saveCandidateKnowledge(value) {
    setLoadingKnowledgesCandidato(true);
    let data;
    if (typeof value === "string") {
      data = {
        nombre: value,
        idConocimiento: 0,
      };
    } else if (value?.id === 0) {
      const foundKnowledge = allKnowledges.find(
        (knowledge) =>
          normalizeString(knowledge?.nombre) === normalizeString(value?.nombre)
      );

      if (foundKnowledge) {
        data = {
          nombre: foundKnowledge.nombre,
          idConocimiento: foundKnowledge.id,
        };
      } else {
        data = {
          nombre: value?.nombre,
          idConocimiento: value?.id,
        };
      }
    } else {
      data = {
        nombre: value?.nombre,
        idConocimiento: value?.id,
      };
    }

    const response = await addCandidateKnowledge(
      returnLangId(i18next.languages[0]),
      data
    );
    if (response.status === 200) {
      handleGetCandidateKnowledge();
      handleGetKnowledges();
    } else {
      setLoadingKnowledgesCandidato(false);
    }
  }

  async function deleteKnowledge(value) {
    setLoadingKnowledgesCandidato(true);
    const response = await deleteCandidateKnowledge(
      returnLangId(i18next.languages[0]),
      value.id
    );
    if (response.status === 200) {
      handleGetCandidateKnowledge();
    } else {
      setLoadingKnowledgesCandidato(false);
    }
  }

  function setIndicadorStateValue(indicador, value) {
    switch (indicador) {
      case indicadorCv.EXPERIENCIA_LABORAL:
        setNoTieneExpLaboral(value);
        break;
      case indicadorCv.CONOCIMIENTOS:
        setNoTieneConocimientos(value);
        break;
      case indicadorCv.FORMACIONES:
        setNoTieneFormacion(value);
        break;
      case indicadorCv.IDIOMAS:
        setNoTieneIdiomas(value);
        break;
    }
  }

  async function handleUpdateIndicadoresCvCandidato(indicador, value) {
    setIsSwitchDisable(true);
    const data = {
      idIndicador: indicador,
      valorIndicador: !value,
    };
    setIndicadorStateValue(indicador, value);
    const response = await updateIndicadoresCvCandidato(
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
    } else {
      setIndicadorStateValue(indicador, !value);
      switch (response.status) {
        case 404:
          props.enqueueSnackbar(t("curriculum.candidate_not_found"), {
            variant: "error",
          });
          break;
        default:
          props.enqueueSnackbar(t("curriculum.switch_error"), {
            variant: "error",
          });
          break;
      }
    }
    setIsSwitchDisable(false);
  }

  async function handleDeleteProfile() {
    setDatosPersonalesLoaded(false);
    const response = await deleteProfileCandidate();
    if (response.status === 200) {
      await auth.removeUser();
      setDatosPersonalesLoaded(true);
      props.setLogged(false);
      props.history.push("/logout_delete");
    } else {
      setDatosPersonalesLoaded(true);
      if (response.status === 404) {
        props.enqueueSnackbar(
          t("curriculum.error_not_found_delete_candidate"),
          {
            variant: "error",
          }
        );
      } else {
        props.enqueueSnackbar(t("curriculum.error_delete_candidate"), {
          variant: "error",
        });
      }
      setOtherData(null);
    }
  }

  function handleSetDatosPersonales() {
    setNombre(datosPersonales.nombre ? datosPersonales.nombre.trim() : "");
    setPrimerApellido(
      datosPersonales.primerApellido
        ? datosPersonales.primerApellido.trim()
        : ""
    );
    setSegundoApellido(
      datosPersonales.segundoApellido
        ? datosPersonales.segundoApellido.trim()
        : ""
    );
    setPresentacion(
      datosPersonales.presentacion
        ? datosPersonales.presentacion.trim().length > 370
          ? `${datosPersonales.presentacion.slice(0, 370).trim()}...`
          : datosPersonales.presentacion
        : null
    );
    setFechaNacimiento(
      datosPersonales.fechaNacimiento
        ? formatToLocalDate(new Date(datosPersonales.fechaNacimiento.trim()))
        : null
    );
    setNacionalidad(
      datosPersonales.nacionalidad
        ? nationalityOptions
            .find(
              (element) => Number(element.id) === datosPersonales.nacionalidad
            )
            ?.name.trim() ?? ""
        : null
    );
    setTipoIdentificacion(
      datosPersonales.tipoIdentificacion
        ? identificationTypes
            .find(
              (element) =>
                Number(element.id) === datosPersonales.tipoIdentificacion
            )
            ?.name.trim() ?? ""
        : ""
    );
    setIdentificacion(
      datosPersonales.identificacion
        ? datosPersonales.identificacion.trim()
        : ""
    );
    setCorreoElectronico(
      datosPersonales.correoElectronico
        ? datosPersonales.correoElectronico.trim()
        : ""
    );
    setTelefono(
      datosPersonales.telefono
        ? datosPersonales.telefono.numero
          ? datosPersonales.telefono.numero.trim()
          : ""
        : null
    );
    setPrefijo(
      datosPersonales.telefono
        ? datosPersonales.telefono.prefijo
          ? datosPersonales.telefono.prefijo.trim()
          : ""
        : null
    );
    setOtroTelefono(
      datosPersonales.otroTelefono
        ? datosPersonales.otroTelefono.numero
          ? datosPersonales.otroTelefono.numero.trim()
          : ""
        : null
    );
    setPrefijoOtroTelefono(
      datosPersonales.otroTelefono
        ? datosPersonales.otroTelefono.prefijo
          ? datosPersonales.otroTelefono.prefijo.trim()
          : ""
        : null
    );
    setProvincia(
      datosPersonales.provincia
        ? provinces
            .find((element) => Number(element.id) === datosPersonales.provincia)
            ?.name.trim() ?? ""
        : ""
    );
    setLocalidad(
      datosPersonales.localidad
        ? locations
            .find((element) => Number(element.id) === datosPersonales.localidad)
            ?.name.trim() ?? ""
        : null
    );
    setCodigoPostal(
      datosPersonales.codigoPostal ? datosPersonales.codigoPostal.trim() : ""
    );
    setDiscapacidad(
      datosPersonales.idDiscapacidad && datosPersonales.idDiscapacidad !== 1
        ? discapacityOptions
            .find((element) => element.id === datosPersonales.idDiscapacidad)
            ?.name.trim() ?? ""
        : null
    );
    setIdFotografia(
      datosPersonales.idFotografia ? datosPersonales.idFotografia : null
    );
    setPerfilesDigitales(
      datosPersonales.perfilesDigitales
        ? datosPersonales.perfilesDigitales
        : null
    );
    setFechaModificacion(
      datosPersonales.fechaModificacion
        ? formatToLocalDate(new Date(datosPersonales.fechaModificacion))
        : "-"
    );
    setNoTieneExpLaboral(
      datosPersonales.tieneExpLaboral ? !datosPersonales.tieneExpLaboral : true
    );
    setNoTieneFormacion(
      datosPersonales.tieneFormacion ? !datosPersonales.tieneFormacion : true
    );
    setNoTieneIdiomas(
      datosPersonales.tieneIdiomas ? !datosPersonales.tieneIdiomas : true
    );
    setNoTieneConocimientos(
      datosPersonales.tieneConocimientos
        ? !datosPersonales.tieneConocimientos
        : true
    );
    setCurriculumDataAction(datosPersonales);
  }

  async function getProfilePic() {
    setLoadingPic(true);
    try {
      const response = await downloadDocumentInform(idFotografia);
      if (response.status === 200 || response.status === 201) {
        const result = await response.json();
        const obj_url = URL.createObjectURL(
          new Blob([base64ToArrayBuffer(result)], {
            type: `application/image/*`,
          })
        );
        setRefPicture(obj_url);
      }
      setLoadingPic(false);
    } catch (error) {
      setLoadingPic(false);
      props.enqueueSnackbar(t("candidateDashboard.error_loading_img"), {
        variant: "error",
      });
    }
  }

  function profilePicture() {
    return loadingPic ? (
      <img src="/images/loading.gif" width={64} height={64}/>
    ) : refPicture ? (
      <img src={refPicture} className={classes.photo} alt="" />
    ) : (
      <CandidatePhoto title={t("icons_tooltips.candidate_picture")} />
    );
  }

  function setCurriculumDataAction(datosPersonales) {
    const data = {
      nombre: datosPersonales.nombre ? datosPersonales.nombre : "",
      primerApellido: datosPersonales.primerApellido
        ? datosPersonales.primerApellido
        : "",
      segundoApellido: datosPersonales.segundoApellido
        ? datosPersonales.segundoApellido
        : "",
      correoElectronico: datosPersonales.correoElectronico
        ? datosPersonales.correoElectronico
        : "",
      prefijo: datosPersonales.telefono
        ? datosPersonales.telefono.prefijo
          ? `${datosPersonales.telefono.prefijo.trim()}`
          : ""
        : null,
      telefono: datosPersonales.telefono
        ? datosPersonales.telefono.numero
          ? datosPersonales.telefono.numero.trim()
          : ""
        : null,
      idFotografia: datosPersonales.idFotografia,
      fechaModificacion: datosPersonales.fechaModificacion
        ? formatToLocalDate(new Date(datosPersonales.fechaModificacion))
        : "-",
    };
    actions.setPersonalDataAction(data);
    actions.setCandidateIdPhotography(datosPersonales.idFotografia);
  }

  function tuCurriculumHeader() {
    return (
      <div className={classes.col1}>
        <div className={classes.summary_icon_container}>
          <CurriculumIcon
            title={t("icons_tooltips.curriculum_space")}
            color="#DA291C"
            noShrink={true}
          />
        </div>
        <div className={classes.title_text}>{t("curriculum.title")}</div>
        {!matchesMobile && (
          <div className={classes.candidate_photo_container}>
            {profilePicture()}
          </div>
        )}
        {datosPersonalesSection()}
      </div>
    );
  }

  function dataSectionHeader(
    icon,
    title,
    linkText, 
    isPersonalData = false,
    onClick = null,
    hideButton = false
  ) {
    return (
      <div
        className={`${
          isPersonalData && !matchesMobile
            ? classes.data_section_header_customize
            : classes.data_section_header
        } `}
      >
        <div
          className={`${
            isPersonalData && !matchesMobile
              ? classes.data_section_icon_and_title_customize
              : classes.data_section_icon_and_title
          } `}
        >
          {icon}
          <div
            className={`${
              isPersonalData && !matchesMobile
                ? classes.data_section_title_customize
                : classes.data_section_title
            } `}
          >
            {title}
          </div>
        </div>
        {!hideButton && (
          <a className={classes.data_section_linkbutton} onClick={onClick}>
            {linkText}
          </a>
        )}
      </div>
    );
  }

  function renderShowAll(showAllVar, onClick, marginTop = 0) {
    return (
      <div
        className={classes.see_all_otros_datos_style}
        onClick={onClick}
        style={{ marginTop: marginTop }}
      >
        <div className={`${showAllVar ? classes.rotate_icon : ""}`}>
          <PriorityLow title={t("icons_tooltips.expand")} />
        </div>
        <div style={{ marginLeft: "9px" }}>
          {`${showAllVar ? t("common.see_less") : t("common.see_all")}`}
        </div>
      </div>
    );
  }

  function personalData() {
    return (
      <div className={classes.personal_data_section_container}>
        <div className={classes.name_and_date_grid}>
          <div className={classes.fullname}>
            {`${nombre} ${primerApellido} ${segundoApellido}`}
          </div>
          <div className={classes.candidate_last_update}>
            {fechaModificacion &&
              `${t("curriculum.last_update")} ${fechaModificacion}`}
          </div>
        </div>
        {seeAllPersonalData ? (
          <div className={classes.personal_data_section_container}>
            {presentacion && (
              <div
                className={classes.personal_data_inner_sections}
              >{`"${presentacion}"`}</div>
            )}
            <div className={classes.personal_data_inner_sections}>
              <div className={classes.email}>{correoElectronico}</div>
              <div>{prefijo && `${"+" + prefijo} ${telefono && telefono}`}</div>
              {prefijoOtroTelefono && otroTelefono && (
                <div>
                  {prefijoOtroTelefono &&
                    `+${prefijoOtroTelefono} ${otroTelefono}`}
                </div>
              )}
              {fechaNacimiento && <div>{fechaNacimiento}</div>}
            </div>
            <div className={classes.personal_data_inner_sections}>
              {localidad && <div>{localidad}</div>}
              <div>{`${codigoPostal && codigoPostal} ${provincia}`}</div>
              {nacionalidad && (
                <div>{`${t("curriculum.nationality")} ${nacionalidad}`}</div>
              )}
              <div>{`${tipoIdentificacion && tipoIdentificacion} ${
                identificacion && identificacion
              }`}</div>
              {discapacidad && (
                <div>{`${t("curriculum.disability")} ${discapacidad}`}</div>
              )}
            </div>
            <div className={classes.digital_profiles_sections}>
              {perfilesDigitales?.map((profile) => (
                <div
                  className={classes.digital_profiles_button}
                  onClick={() => window.open(profile.url)}
                >
                  {renderSwitchDigitalProfile(profile.tipoPerfilDigital)}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.personal_data_inner_sections}>
            <div>{correoElectronico}</div>
            <div> {prefijo && `+${prefijo} ${telefono}`}</div>
          </div>
        )}
        <div
          className={classes.see_notifications_button}
          onClick={() => setSeeAllPersonalData(!seeAllPersonalData)}
        >
          <div>
            {`${
              seeAllPersonalData
                ? t("curriculum.see_less")
                : t("curriculum.see_all")
            }`}
          </div>
          <div className={`${seeAllPersonalData ? classes.rotate_icon : ""}`}>
            <PriorityLow />
          </div>
        </div>
      </div>
    );
  }

  function datosPersonalesSection() {
    return (
      <div className={classes.data_section}>
        {dataSectionHeader(
          <PersonalData />,
          t("curriculum.personalData"),
          t("curriculum.edit"),
          true,
          () => props.history.push("/edit_personal_data")
        )}
        {matchesMobile && <ProfilePicture />}
        {personalData()}
      </div>
    );
  }

  function noDataYet(text, checked, setter) {
    return (
      <div className={classes.no_data_yet_container}>
        <div className={classes.no_data_yet_text}>{text}</div>
        <AntSwitch
          checked={checked}
          setter={setter}
          disabled={isSwitchDisable}
        />
      </div>
    );
  }

  function orderExperienceCandidate(a, b) {
    if (a.fechaInicio && b.fechaInicio && a.fechaFin && b.fechaFin) {
      if (
        a.fechaFin === "0001-01-01T00:00:00" &&
        b.fechaFin === "0001-01-01T00:00:00"
      ) {
        return new Date(b.fechaInicio) - new Date(a.fechaInicio);
      } else {
        if (a.fechaFin === "0001-01-01T00:00:00") {
          return -1;
        }
        if (b.fechaFin === "0001-01-01T00:00:00") {
          return 1;
        }
      }
      return new Date(b.fechaInicio) - new Date(a.fechaInicio);
    }
  }

  function renderEtt(exp) {
    if (exp.esEtt) return ` - ${exp.ett.nombre}`;
    else return null;
  }

  const handleMinOrMaxSalary = (exp) => {
    return (
      <>
        {exp.salarioMinimo ?
          <>
            {`${t("curriculum.minSalaryReceived")}
              ${exp.salarioMinimo.nombre} ${exp.tipoSalario.nombre}`}
          </>
          :
          <>
            {`${t("curriculum.maxSalaryReceived")}
              ${exp.salarioMaximo.nombre} ${exp.tipoSalario.nombre}`}
          </>
        }
      </>
    )
  }

  function printReceivedSalary(experience) {
    return (
      experience.tipoSalario &&
      (experience.salarioMinimo || experience.salarioMaximo) && (
        <>
          <div>
            {experience.salarioMinimo && experience.salarioMaximo ?
              <>
                {`${t("curriculum.salaryReceived")}:
                  ${experience.salarioMinimo.nombre} - ${experience.salarioMaximo.nombre} ${experience.tipoSalario.nombre}`}
              </>
              : handleMinOrMaxSalary(experience)}
          </div>
        </>
      )
    )
  }

  function handleStaffOrManager(msg, managerOrStaff) {
    return (
      <div>
        {managerOrStaff &&
          `${msg}: ${managerOrStaff.nombre}`
        }
      </div>
    )
  }

  function printLevelExperience(experiencie) {
    return experiencie.nivel &&
      <div>
        {`${t("curriculum.levelExperience")}: ${experiencie?.nivel.nombre}`}
      </div>
  }

  const Experiencias = () =>
    experience
      .slice(0, showAllExperiences ? experience.length : 2)
      .map((exp, index) => (
        <div key={index} className={classes.experience_container}>
          <div className={classes.experience_title_container}>
            <div className={classes.experience_title}>{exp.puesto.nombre}</div>
            <div
              onClick={() =>
                props.history.push(
                  `/work_experience/${exp.idExperienciaLaboral}`
                )
              }
              className={classes.experience_edit_button}
            >
              <EditIcon title={t("icons_tooltips.edit_experience")} />
            </div>
          </div>
          <div className={classes.experience_details}>
            {exp.empresa} ({exp.sector.nombre}) {renderEtt(exp)}
          </div>
          <div className={classes.container_experience}>
            {exp.funciones.map((fun, index) => (
              <div className={classes.experience_ind} key={index}>
                <div
                  className={classes.experience_separator}
                  style={{ margin: "3px 0px"}}
                ></div>
                <div
                  key={index}
                  className={`${classes.experience_details} ${classes.experience_functions}`}
                >
                  {fun.nombre}
                </div>
              </div>
            ))}
          </div>
          <div className={exp.tipoSalario && (exp.salarioMinimo || exp.salarioMaximo)  ? "" : classes.no_Space}>
            <div className={classes.container_experience}>
              {printReceivedSalary(exp)}
            </div>
          </div>
          <div className={!exp.nivel ? classes.no_Space : ""}>
            <div className={classes.container_experience}>
              {printLevelExperience(exp)}
              {handleStaffOrManager(t("curriculum.directManager"), exp.nivelResponsable)}
              {handleStaffOrManager(t("curriculum.staffInCharge"), exp.personalACargo)}
            </div>
          </div>
          <div>
            <div className={classes.experience_dates}>
              {new Date(exp.fechaInicio).getFullYear()} -
            </div>
            <div className={classes.experience_dates}>
              {exp.fechaFin && new Date(exp.fechaFin).getFullYear() !== 1
                ? new Date(exp.fechaFin).getFullYear()
                : t("curriculum.actually")}
            </div>
          </div>
          <div className={classes.container_conocimientos}>
            {exp.conocimientos.map((con, index) => (
              <div key={index} className={`${classes.chip}`}>
                {con?.nombre}
              </div>
            ))}
          </div>
          <div
            className={
              exp.referencias.length > 0
                ? classes.experience_details
                : classes.experience
            }
          >
            {exp.referencias.length > 0 ? t("curriculum.references") : ""}
            {exp.referencias.map((r) => (
              <div className={classes.referencia}>
                <div className={classes.referencia_nombre}>{r.nombre}</div>
                {r.telefono.numero ? (
                  <div>
                    {t("curriculum.phone")}: +{r.telefono.prefijo}{" "}
                    {r.telefono.numero}
                  </div>
                ) : (
                  <div />
                )}
                <div className={classes.referencia_email}>
                  {r.correoElectronico}
                </div>
              </div>
            ))}
          </div>
        </div>
      ));

  function experienciaSection() {
    return (
      <div className={classes.data_section}>
        {dataSectionHeader(
          <YourExperience />,
          t("curriculum.experience"),
          t("curriculum.add"),
          false,
          () => props.history.push("/work_experience"),
          noTieneExpLaboral
        )}
        {experience ? (
          <>
            {experience.length > 0 ? (
              <Experiencias />
            ) : (
              noDataYet(
                t("curriculum.no_experience"),
                noTieneExpLaboral,
                () => {
                  handleUpdateIndicadoresCvCandidato(
                    indicadorCv.EXPERIENCIA_LABORAL,
                    !noTieneExpLaboral
                  );
                }
              )
            )}
            {experience &&
              experience.length > 2 &&
              renderShowAll(showAllExperiences, () =>
                setShowAllExperiences(!showAllExperiences)
              )}
          </>
        ) : (
          <div style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </div>
        )}
      </div>
    );
  }

  function editarFormacion(formacion) {
    actions.setFormationToEditAction(formacion);
    props.history.push("/formacion");
  }

  const nivelEstudio = (idNivelEstudios) => {
    var nivel = niveles_de_estudio.find(
      (nivel) => Number(nivel.id) === Number(idNivelEstudios)
    );
    return nivel ? nivel.name : "";
  };

  const areaEstudio = (idAreaEstudios) => {
    var area = areas_estudio.find(
      (area) => Number(area.id) === Number(idAreaEstudios)
    );
    return area ? area.name : "";
  };

  function renderSingleFormacionReglada(formacion) {
    return (
      <div
        className={classes.your_data_inner_container}
        key={formacion.idFormacion}
      >
        <div className={classes.formacion_header}>
          <div className={classes.formacion_title}>
            {nivelEstudio(formacion.idNivelEstudios)}
          </div>
          <div
            onClick={() => editarFormacion(formacion)}
            className={classes.edit_your_single_data}
          >
            <EditIcon title={t("icons_tooltips.edit_formation")} />
          </div>
        </div>
        <div>{formacion.titulo}</div>
        <div>{areaEstudio(formacion.idAreaEstudios)}</div>
        {formacion.centro && formacion.centro.trim() !== "" && (
          <div>{formacion.centro}</div>
        )}
        <div>{`${getYear(formacion.fechaInicio)} - ${
          formacion.esFormacionActual
            ? t("curriculum.currently_studying")
            : getYear(formacion.fechaFin)
        }`}</div>
      </div>
    );
  }

  function renderSingleCertificacion(formacion) {
    return (
      <div className={classes.formacion_container} key={formacion.idFormacion}>
        <div className={classes.formacion_header}>
          <div className={classes.formacion_title}>
            {formacion.certificacion.nombre}
          </div>
          <div
            onClick={() => editarFormacion(formacion)}
            className={classes.edit_your_single_data}
          >
            <EditIcon title={t("icons_tooltips.edit_certificate")} />
          </div>
        </div>
        {formacion.centro && formacion.centro.trim() !== "" && (
          <div>{formacion.centro}</div>
        )}
        {formacion.tieneFechaValidez && formacion.fechaFinValidez && (
          <div>{`${t("curriculum.valid_until")} ${getYear(
            formacion.fechaFinValidez
          )}`}</div>
        )}
      </div>
    );
  }

  function renderGridFormaciones(isCertificados) {
    let certificados = formaciones.filter(
      (formacion) => formacion.idNivelEstudios === NIVEL_CERTIFICACIONES
    );
    let formacionesRegladas = formaciones.filter(
      (formacion) => formacion.idNivelEstudios !== NIVEL_CERTIFICACIONES
    );
    return (
      <div className={classes.formacion_title_grid}>
        <div className={classes.formacion_section_title}>
          {isCertificados
            ? t("curriculum.certifications")
            : t("curriculum.formation")}
        </div>
        {isCertificados ? (
          <>
            <div className={classes.your_data_grid}>
              {certificados
                .slice(0, showAllCertificaciones ? certificados.length : 2)
                .map((formacion) => renderSingleCertificacion(formacion))}
            </div>
            {certificados.length > 2 &&
              renderShowAll(
                showAllCertificaciones,
                () => setShowAllCertificaciones(!showAllCertificaciones),
                "20px"
              )}
          </>
        ) : (
          <>
            <div className={classes.your_data_grid}>
              {formacionesRegladas
                .slice(
                  0,
                  showAllFormacionesRegladas ? formacionesRegladas.length : 2
                )
                .map((formacion) => renderSingleFormacionReglada(formacion))}
            </div>
            {formacionesRegladas.length > 2 &&
              renderShowAll(
                showAllFormacionesRegladas,
                () =>
                  setShowAllFormacionesRegladas(!showAllFormacionesRegladas),
                "20px"
              )}
          </>
        )}
      </div>
    );
  }

  function renderFormaciones() {
    return (
      <div className={classes.grids_formaciones}>
        {formaciones.filter(
          (formacion) => formacion.idNivelEstudios !== NIVEL_CERTIFICACIONES
        ).length > 0 && renderGridFormaciones(false)}
        {formaciones.filter(
          (formacion) => formacion.idNivelEstudios === NIVEL_CERTIFICACIONES
        ).length > 0 && renderGridFormaciones(true)}
      </div>
    );
  }

  function formacion() {
    return (
      <div className={classes.data_section}>
        {dataSectionHeader(
          <YourFormation />,
          t("curriculum.education"),
          t("curriculum.add"),
          false,
          () => props.history.push("/formacion"),
          noTieneFormacion
        )}
        {formaciones ? (
          formaciones.length > 0 ? (
            renderFormaciones()
          ) : (
            noDataYet(t("curriculum.no_education"), noTieneFormacion, () =>
              handleUpdateIndicadoresCvCandidato(
                indicadorCv.FORMACIONES,
                !noTieneFormacion
              )
            )
          )
        ) : (
          <div style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </div>
        )}
      </div>
    );
  }

  function editarIdioma(idioma) {
    actions.setLanguageToEditAction(idioma);
    props.history.push("/idioma");
  }

  const getNombreIdioma = (idIdioma) => {
    var result = idiomas_list.find(
      (lang) => Number(lang.id) === Number(idIdioma)
    );
    return result ? result.name : "";
  };

  const getNombreNivel = (idNivel) => {
    var result = niveles_idioma.find((n) => Number(n.id) === Number(idNivel));
    return result ? result.name : "";
  };

  function renderSingleIdioma(idioma) {
    return (
      <div className={classes.your_data_inner_container} key={idioma.idIdioma}>
        <div className={classes.single_language_header}>
          <div>{getNombreIdioma(idioma.idIdioma)}</div>
          <div
            onClick={() => editarIdioma(idioma)}
            className={classes.edit_your_single_data}
          >
            <EditIcon title={t("icons_tooltips.edit_language")} />
          </div>
        </div>
        <div className={classes.languages_inner_grid}>
          <div style={{ fontWeight: 600 }}>{`${t("curriculum.written")}:`}</div>
          <div>{getNombreNivel(idioma.idNivelEscrito)}</div>
          <div style={{ fontWeight: 600 }}>{`${t("curriculum.readed")}:`}</div>
          <div>{getNombreNivel(idioma.idNivelLeido)}</div>
          <div style={{ fontWeight: 600 }}>{`${t("curriculum.spoken")}:`}</div>
          <div>{getNombreNivel(idioma.idNivelHablado)}</div>
          {idioma.certificados && idioma.certificados.length > 0 && (
            <div style={{ fontWeight: 600 }}>{`${t(
              "curriculum.certificates"
            )}:`}</div>
          )}
          {idioma.certificados && idioma.certificados.length > 0 && (
            <div className={classes.certificate_inner_list}>
              {idioma.certificados.map((certificado) => (
                <div
                  className={classes.certificate}
                >{`${certificado.nombreCertificacion} (${certificado.año})`}</div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderIdiomas() {
    return (
      <>
        <div className={classes.your_data_grid}>
          {idiomas
            .slice(0, showAllIdiomas ? idiomas.length : 2)
            .map((idioma) => renderSingleIdioma(idioma))}
        </div>
        {idiomas &&
          idiomas.length > 2 &&
          renderShowAll(showAllIdiomas, () =>
            setShowAllIdiomas(!showAllIdiomas)
          )}
      </>
    );
  }

  function tusIdiomas() {
    return (
      <div className={classes.data_section}>
        {dataSectionHeader(
          <YourLanguages />,
          t("curriculum.languages"),
          t("curriculum.add"),
          false,
          () => props.history.push("/idioma"),
          noTieneIdiomas
        )}
        {idiomas ? (
          idiomas.length > 0 ? (
            renderIdiomas()
          ) : (
            noDataYet(t("curriculum.no_languages"), noTieneIdiomas, () =>
              handleUpdateIndicadoresCvCandidato(
                indicadorCv.IDIOMAS,
                !noTieneIdiomas
              )
            )
          )
        ) : (
          <div style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </div>
        )}
      </div>
    );
  }

  const handleKnowledgeError = () => {
    if (knowledgeError) {
      props.enqueueSnackbar(knowledgeError, {
        variant: "error",
      });
    }
  };

  function conocimientos() {
    return (
      <div className={classes.data_section}>
        {dataSectionHeader(
          <YourKnowledge />,
          t("curriculum.knowledge"),
          t("curriculum.add"),
          false,
          null,
          true
        )}
        {candidateKnowledges ? (
          <>
            {candidateKnowledges.length > 0
              ? null
              : noDataYet(
                  t("curriculum.no_knowledge"),
                  noTieneConocimientos,
                  () =>
                    handleUpdateIndicadoresCvCandidato(
                      indicadorCv.CONOCIMIENTOS,
                      !noTieneConocimientos
                    )
                )}
            {!noTieneConocimientos && (
              <AutocompleteWithChips
                options={allKnowledges}
                values={candidateKnowledges ? candidateKnowledges : []}
                label={t("curriculum.knowledges")}
                setter={setterKnowledge}
                setterOptions={setterKnowledgeOptions}
                saveEvent={saveCandidateKnowledge}
                deleteEvent={deleteKnowledge}
                loadingChips={loadingKnowledgesCandidato}
                useGrid={true}
                onOpen={handleKnowledgeError}
                moveLabel={true}
                maxLength={150}
                container={classes.container_knowledge}
              />
            )}
          </>
        ) : (
          <div style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </div>
        )}
      </div>
    );
  }

  function otrosDatos() {
    return (
      <div className={classes.data_section}>
        {dataSectionHeader(
          <OtherData />,
          t("curriculum.otherData"),
          t("curriculum.edit"),
          false,
          () => props.history.push("/otros_datos")
        )}
        {otherData ? (
          <>
            <li className={classes.otros_datos_row}>
              {printTiposCarnetConducir()}
            </li>
            <li className={classes.otros_datos_row}>
              {printTieneVehiculoPropio()}
            </li>
            <li className={classes.otros_datos_row}>
              {printTrabajandoActualmente()}
            </li>
            <li className={classes.otros_datos_row}>
              {printTipoDisponibilidadViajar()}
            </li>
            {showAllOtrosDatos && (
              <>
                <li className={classes.otros_datos_row}>
                  {printTipoDisponibilidadCambioResidencia()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printTiposHorario()}
                </li>

                <li className={classes.otros_datos_row}>
                  {printPermiteFirmaDigital()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printPermiteGeolocalizacion()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printTiposContrato()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printCobraPrestacionParo()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printAceptaDiasAislados()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printSalarioMinimo()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printSalarioDeseado()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printPuestoDeseadoFavorito()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printPermisoTrabajo()}
                </li>
                <li className={classes.otros_datos_row}>
                  {printCertificadoPenales()}
                </li>
              </>
            )}
            {renderShowAll(showAllOtrosDatos, () =>
              setShowAllOtrosDatos(!showAllOtrosDatos)
            )}
          </>
        ) : (
          <div style={{ color: "#DA291C" }}>
            {t("curriculum.section_error")}
          </div>
        )}
      </div>
    );
  }

  

  function printTipoDisponibilidadViajar() {
    return `${t("curriculum.travel_available")} ${getTipoDisponibilidadViajar().name}`;
  }

  function printTiposCarnetConducir() {
    return otherData.tiposCarnetConducir.length > 0
      ? `${t("curriculum.driving_lincese")} ${getTiposCarnetConducir().map(
          (carnet) => ` ${carnet.name}`
        )}`
      : t("curriculum.no_driving_lincese");
  }

  function printTieneVehiculoPropio() {
    return otherData.tieneVehiculoPropio
      ? t("curriculum.own_car")
      : t("curriculum.no_own_car");
  }

  function printTrabajandoActualmente() {
    return otherData.trabajaActualmente
      ? t("curriculum.currently_working")
      : t("curriculum.currently_not_working");
  }

  function printTipoDisponibilidadCambioResidencia() {
    return `${t("curriculum.residence_change_available")} ${
      getTipoDisponibilidadCambioResidencia().name
    }`;
  }

  function printTiposHorario() {
    return otherData.tiposHorario.length > 0
      ? `${t("curriculum.workday_type")} ${getTiposHorario().map(
          (horario) => ` ${horario.name}`
        )}`
      : t("curriculum.no_workday_type");
  }

  function printPermiteFirmaDigital() {
    return otherData.permiteFirmaDigital
      ? t("curriculum.digital_signature")
      : t("curriculum.no_digital_signature");
  }

  function printPermiteGeolocalizacion() {
    return otherData.permiteGeolocalizacion
      ? t("curriculum.geolocalization")
      : t("curriculum.no_geolocalization");
  }

  function printCobraPrestacionParo() {
    return otherData.cobraPrestacionParo
      ? t("curriculum.paid_unemployment")
      : t("curriculum.no_paid_unemployment");
  }

  function printAceptaDiasAislados() {
    return otherData.aceptaDiasAislados
      ? t("curriculum.work_isolated_days")
      : t("curriculum.no_work_isolated_days");
  }

  function printSalarioMinimo() {
    return otherData.salarioMinimo
      ? `${t("curriculum.minimum_wage")} ${getSalarioMinimo().name}`
      : t("curriculum.no_minimum_wage");
  }

  function printSalarioDeseado() {
    return otherData.salarioDeseado
      ? `${t("curriculum.desired_wage")} ${getSalarioDeseado().name}`
      : t("curriculum.no_desired_wage");
  }

  function printPuestoDeseadoFavorito() {
    return `${t("curriculum.employe_desire")}: ${getPuestoDeseadoFavorito().nombre ?? ""}`;
  }

  function printPermisoTrabajo() {  
    return `${t("other_data.work_permit_simple")}: ${otherData.tienePermisoTrabajo ? "Si" : "No"}`;
  }

  function printCertificadoPenales() {
    return `${t("other_data.criminal_record")}: ${otherData.tieneCertificadoPenales ? "Si" : "No"}`;
  }
  
  function printTiposContrato() {
    return otherData.tiposContrato?.length > 0
      ? `${t("curriculum.type_contract")} ${getTiposContrato().map(
          (c) => ` ${c.name}`
        )}`
      : t("curriculum.no_type_contract");
  }

  function cv() {
    return (
      <div className={classes.data_section}>
        {dataSectionHeader(
          <YourCV />,
          t("curriculum.title"),
          t("curriculum.uploadFile"),
          false,
          () => {
            inputRef.current.click();
          },
          cvFile || isCvFileLoading
        )}
        {isCvFileLoading ? (
          <img alt="./assets/animations/loading.gif" width={40} height={40}/>
        ) : !cvFile ? (
          <div className={classes.no_data_yet_text}>
            {t("curriculum.no_document")}
          </div>
        ) : (
          <div className={classes.your_data_grid}>
            <div className={classes.cv_row_style}>
              <div>{cvFile.nombre}</div>
              {!isCvActionLoading ? (
                <div className={classes.cv_buttons}>
                  <div
                    className={classes.clickable_style}
                    onClick={() => handleDownloadDocument()}
                  >
                    <DownloadDocument title={t("icons_tooltips.download")} />
                  </div>
                  {cvFile.idAplicacion !== ID_APLICACION_GESTOR ?
                  <div
                    className={classes.clickable_style}
                    onClick={() => {
                      setTypeDialog("deleteDoc");
                      setIsDialogOpen(true);
                    }}
                  >
                    <TrashRed title={t("icons_tooltips.delete")} />
                  </div> : null}
                </div>
              ) : (
                <img alt="./images/loading.gif" width={32} height={32}/>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  const isAllLoaded = () => {
    return (
      datosPersonalesLoaded &&
      formacionesLoaded &&
      idiomasLoaded &&
      experienciaLaboralLoaded &&
      cvLoaded &&
      otrosDatosLoaded
    );
  };

  function getContentAlert() {
    if (typeDialog === "deleteProfile") {
      return (
        <div>
          <p>{t("curriculum.delete_profile_body_alert")}</p>
          <p>{t("curriculum.delete_profile_body_alert2")}</p>
        </div>
      );
    }
    return null;
  }

  useEffect(() => {}, []);

  return (
    <>
      <Layout history={props.history} loading={!isAllLoaded()}>
        <Snackbar
          text={snackbarText}
          isSnackBarOpen={isSnackBarOpen}
          handleIsSnackBarOpen={setIsSnackBarOpen}
        />
        <div className={classes.principal_container} ref={innerBody}>
          {tuCurriculumHeader()}
          <div className={classes.col2}>
            <div className={classes.data_sections_container}>
              <div className={classes.section_border} />
              {experienciaSection()}
              <div className={classes.section_border} />
              {formacion()}
              <div className={classes.section_border} />
              {tusIdiomas()}
              <div className={classes.section_border} />
              {conocimientos()}
              <div className={classes.section_border} />
              {otrosDatos()}
              <div className={classes.section_border} />
              {cv()}
              <div className={classes.section_border} />
            </div>
            <ButtonOutlined
              text={t("curriculum.create_document")}
              customClass={classes.custom_button_size}
              disabled={
                !datosPersonales ||
                !experience ||
                !formaciones ||
                !idiomas ||
                !candidateKnowledges ||
                !allKnowledges ||
                !otherData
              }
              onClick={() =>
                props.history.push({
                  pathname: "/cv",
                  state: {
                    datosPersonales,
                    experience,
                    formaciones,
                    idiomas,
                    candidateKnowledges,
                    allKnowledges,
                    refPic,
                    otherData,
                    locations,
                    countries
                  },
                })
              }
              startIcon={
                <Download title={t(`icons_tooltips.create_document`)} />
              }
            />
            <a
              className={classes.delete_profile_linkbutton}
              onClick={() => {
                setTypeDialog("deleteProfile");
                setIsDialogOpen(true);
              }}
            >
              {t("curriculum.delete_profile")}
            </a>
          </div>
        </div>
        <input
          type="file"
          className={classes.hidden_input}
          ref={inputRef}
          accept=".doc, .docx, .pdf"
          onChange={(event) => handleSelectFile(event.target.files)}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <AlertDialog
          title={
            typeDialog === "deleteDoc"
              ? t("curriculum.delete_confirm_message")
              : t("curriculum.delete_profile_tittle_alert")
          }
          description={getContentAlert()}
          open={isDialogOpen}
          handleClose={() => {
            setIsDialogOpen(false);
          }}
          handleAccept={() => {
            typeDialog === "deleteDoc"
              ? handleDeleteDocument(cvFile)
              : handleDeleteProfile();
            setIsDialogOpen(false);
          }}
        />
        <Backdrop className={classes.backdrop} open={exporting}>
        <img alt="/images/loading.gif" width={64} height={64}/>
        </Backdrop>
      </Layout>
    </>
  );
}

export default withSnackbar(Curriculum);
