import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { TextField, Chip } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Add from "../assets/icons/cAdd";
import InputWrapper from "./inputWrapper";
import { useTranslation } from "react-i18next";
import { normalizeString } from "../utils/functions";

const useStyles = makeStyles((theme) => ({
  autocomplete_input: {
    padding: "0!important",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 30px white inset",
    },
  },
  chip: {
    backgroundColor: "#EFEFEF",
    borderRadius: "15px",
    height: "30px",
    padding: "7px",
    marginRight: "10px",
    marginTop: "25px",
    maxWidth: "97%",
  },
  chip_delete_icon: {
    color: "#999999 !important",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 5fr",
    gridTemplateRows: "auto auto",
    gridTemplateAreas: '"select selectFilled" "chips chips"',
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto",
      gridTemplateAreas: '"select" "chips"',
    },
  },
  chips_knowledges: {
    gridArea: "chips",
    margin: "-5px",
    paddingTop: 25,
    maxWidth: "100%",
    overflow: "hidden",
  },
  custom_chip: {
    margin: 5,
    "& > .MuiChip-label": {
      marginRight: "8px",
    },
  },
  popupIcon: {
    height: 28,
    width: 28,
  },
  button_add: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    right: 12,
    width: 24,
    height: 24,
  },
  input_autocomplete: {
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      lineHeight: "19px",
    },
    fontSize: 12,
    lineHeight: "16px",
    textOverflow: "ellipsis",
    "-webkit-appearance": "none",
    backgroundColor: "transparent",
    "&::-webkit-clear-button": {
      display: "none",
      "-webkit-appearance": "none",
    },
    "&::-webkit-inner-spin-button": {
      display: "none",
    },
    "&::-webkit-outside-spin-button": {
      display: "none",
    },
    "&::-webkit-calendar-picker-indicator": {
      display: "none",
    },
    "&::placeholder": {
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        lineHeight: "19px",
      },
      fontSize: 12,
      lineHeight: "16px",
      color: "#C4C4C4",
      opacity: 1,
    },
    height: "36px",
    "-webkit-appearance": "none",
    "&:-webkit-autofill": {
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield !important",
    padding: "0px 20px 0px 6px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  },
  root: {
    padding: 0,
    height: "36px",
    "& .MuiAutocomplete-inputRoot": {
      height: "36px",
      padding: 0,
    },
    "& .MuiInputBase-input": {
      padding: "8px 12px",
      "-moz-appearance": "textfield",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        lineHeight: "13px",
      },
      fontSize: 14,
      lineHeight: "16px",
      color: "black",
      transform: "translate(4px, -20px) scale(1)",
      marginBottom: "4px",
    },
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
      transform: "translate(10px, 10px) scale(1)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "80%",
      paddingBottom: 1
    },
    "& label.Mui-focused": {
      color: "#c4c4c4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
        borderWidth: "1px",
        top: 0,
      },
      "& legend": {
        display: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#c4c4c4",
        borderWidth: "1px",
        top: 0,
      },
      "&.Mui-focused legend": {
        display: "none",
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
  },
}));

const filter = createFilterOptions();

const AutocompleteWithChips = ({
  maxLength = false,
  moveLabel,
  wrong,
  required,
  disabled,
  label,
  options,
  values,
  placeholder,
  setter,
  setterOptions,
  saveEvent = null,
  deleteEvent = null,
  useGrid = false,
  loadingChips = false,
  nombreId1 = "id",
  nombreId2 = "id",
  onOpen,
  container,
  noAdd = false,
  paises
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setInputValue("");
  }, [values]);

  function eraseChip(value) {
    if (deleteEvent) {
      deleteEvent(value);
    } else {
      if (!value[nombreId2]) {
        setter(values.filter((val) => value.id || val !== value));
      } else {
        setter(values.filter((val) => val[nombreId1] !== value[nombreId2]));
      }
    }
  }

  function handleMaxLength(value) {
    if (typeof value === "string") {
      if (value.length <= maxLength) {
        setInputValue(value);
      }
    } else {
      if (value.length <= maxLength) {
        setInputValue(value);
      }
    }
  }

  function handleOnChange(event, newValue) {
    if (typeof newValue === "string") {
      if (
        !values.some((v) =>
          typeof v === "string"
            ? normalizeString(v.trim()) === normalizeString(newValue.trim())
            : normalizeString(v.nombre.trim()) ===
              normalizeString(newValue.trim())
        ) &&
        !noAdd
      ) {
        const valueOption = options.find(
          (o) => o.nombre.trim().toLowerCase() === newValue.trim().toLowerCase()
        );
        if (valueOption) {
          setter([...values, valueOption]);
          if (saveEvent) {
            saveEvent(valueOption);
          }
        } else {
          const newValueOption = { id: 0, nombre: newValue };
          setterOptions([...options, newValueOption]);
          setter([...values, newValueOption]);
          if (saveEvent) {
            saveEvent(newValueOption);
          }
        }
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setter([...values, { id: 0, nombre: newValue.inputValue }]);
    } else {
      if (newValue) {
        setter([...values, newValue]);
        setValue(newValue.name);
        if (saveEvent) {
          saveEvent(newValue);
        }
      }
    }
    setInputValue("");
  }

  function handleAddButton() {
    if (inputValue.trim() !== "") {
      handleOnChange(null, inputValue);
    }
  }

  return (
    <div className={useGrid ? (container ? container : classes.container) : ""}>
      <InputWrapper
        type="autocomplete"
        label={moveLabel ? null : label}
        disabled={disabled}
        required={required}
        wrong={wrong}
        moveLabel={moveLabel}
      >
        <Autocomplete
          options={options}
          freeSolo
          disabled={disabled}
          closeIcon={null}
          style={{ width: "100%" }}
          // autoSelect
          forcePopupIcon={false}
          // getOptionLabel={(option) => option.nombre}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Add option datalist
            if (option.name) {
              return option.name;
            }
            // Regular option
            return option.nombre;
          }}
          getOptionDisabled={(option) =>
            values.some((value) => value?.id === option.id)
          }
          classes={{
            input: `${classes.autocomplete_input} `,
            popupIndicator: classes.popupIcon,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
              disabled={disabled}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                autoComplete: "disabled",
                classes: {
                  input: classes.input_autocomplete,
                  root: classes.input_autocomplete,
                },
              }}
              label={moveLabel ? label : null}
              classes={{ root: `${classes.root}` }}
            />
          )}
          onChange={handleOnChange}
          inputValue={inputValue}
          value={value ? value : ""}
          onInputChange={(e, value) =>
            maxLength ? handleMaxLength(value) : setInputValue(value)
          }
          onOpen={onOpen}
        />
        {noAdd ? (
          <div></div>
        ) : (
          <div className={classes.button_add} onClick={handleAddButton}>
            <Add title={t(`icons_tooltips.add`)} />
          </div>
        )}
      </InputWrapper>
      {!loadingChips ? (
        values && values.length > 0 ? (
          <div className={classes.chips_knowledges}>
            {values?.map((value, index) => (
              <Chip
                key={index}
                label={value.nombre || value.name || paises.find((p) => p.id === value.id_country)?.name || value}
                deleteIcon={
                  <div title={t(`icons_tooltips.delete`)}>
                    <HighlightOffIcon />
                  </div>
                }
                className={classes.custom_chip}
                onDelete={() => eraseChip(value)}
                color="secondary"
                classes={{
                  root: classes.chip,
                  deleteIconColorSecondary: classes.chip_delete_icon,
                }}
              />
            )
            )}
          </div>
        ) : null
      ) : (
        <div className={classes.chips_knowledges}>
          <img src="/images/loading.gif" width={40} height={40}/>
        </div>
      )}
    </div>
  );
};

export default AutocompleteWithChips;
