import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Template from "../components/pdf/template";
import { print } from "../components/pdf/buttonPDF";
import { downloadDocumentInform } from "../api/maestro";
import { base64ToArrayBuffer } from "../utils/functions";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    height: "calc(var(--vh, 1vh)*100)",
    minHeight: "100vh",
    minHeight: "calc(var(--vh, 1vh)*100)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loading_container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  template_container: {
    visibility: "hidden",
    height: 0,
    width: 0,
    overflow: "hidden",
  },
}));

function CvGenerator(props) {
  const classes = useStyles();

  const [stateData, setStateData] = useState(null);
  const [height, setHeight] = useState(window.innerHeight);
  const [exporting, setExporting] = useState(true);
  const [picSetted, setPicSetted] = useState(false);
  const [pic, setPic] = useState(null);
  const dataListLocalidades = props.location.state.locations;
  const dataListCountries = props.location.state.countries;

  const tempRef = useRef(null);
  const innerBody = useRef(null);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (props.location.state) {
      setStateData(props.location.state);
    } else {
      props.history.push("/curriculum");
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!exporting) {
      props.history.push({
        pathname: "/curriculum",
        state: {
          ...stateData,
        },
      });
    }
  }, [exporting]);

  useEffect(() => {
    if (stateData) {
      getProfilePic();
    }
  }, [stateData]);

  useEffect(() => {
    if (picSetted) {
      handleExport();
    }
  }, [picSetted]);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  async function getProfilePic() {
    const response = await downloadDocumentInform(
      stateData.datosPersonales.idFotografia
    );
    if (response.status === 200 || response.status === 201) {
      const result = await response.json();
      const obj_url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(result)], {
          type: `application/image/*`,
        })
      );
      setPic(obj_url);
      setPicSetted(true);
    } else {
      setPicSetted(null);
      setPicSetted(true);
    }
  }

  function handleExport() {
    let file = `${stateData.datosPersonales.nombre}${
      stateData.datosPersonales.primerApellido
    }${
      stateData.datosPersonales.segundoApellido &&
      stateData.datosPersonales.segundoApellido.trim() !== ""
        ? stateData.datosPersonales.segundoApellido
        : ""
    }`;
    setTimeout(() => print(file, setExporting), 500);
  }

  return (
    <div
      className={classes.container}
      ref={innerBody}
      style={{ "--vh": height * 0.01 + "px" }}
    >
      <div className={classes.loading_container}>
      <img alt="/images/loading.gif" width={64} height={64}/>
      </div>

      {stateData ? (
        <div className={classes.template_container}>
          <div id="myMm" style={{ height: "1mm" }} />
          <Template
            reference={tempRef}
            personalData={stateData.datosPersonales}
            experiences={stateData.experience}
            formations={stateData.formaciones}
            languages={stateData.idiomas}
            knowledges={stateData.candidateKnowledges}
            knowledgesList={stateData.allKnowledges}
            picture={pic}
            otherData={stateData.otherData}
            regions={dataListLocalidades}
            countries={dataListCountries}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default CvGenerator;
