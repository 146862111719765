import React, { useEffect, useState } from "react";
import Down from "@material-ui/icons/KeyboardArrowDown";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./inputWrapper";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputBase: {
    flex: 1,
    paddingRight: 0,
    height: "100%",
    marginTop: "auto",
  },
  label: {
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      lineHeight: "22px",
    },
    fontSize: 12,
    lineHeight: "16px",
    marginBottom: 4,
  },
  paper: {
    maxHeight: "200px!important",
    overflow: "auto",
  },
  customChip: {
    background: "none",
    height: "unset",
    "&::after": {
      content: "','",
    },
  },
  customChip_deleteIcon: {
    display: "none",
  },
  customChip_label: {
    padding: "unset",
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
      lineHeight: "19px",
    },
    fontSize: 12,
    lineHeight: "16px",
  },
  input_autocomplete: {
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      lineHeight: "19px",
    },
    fontSize: 12,
    lineHeight: "16px",
    textOverflow: "ellipsis",
    "-webkit-appearance": "none",
    backgroundColor: "transparent",
    "&::-webkit-clear-button": {
      display: "none",
      "-webkit-appearance": "none",
    },
    "&::-webkit-inner-spin-button": {
      display: "none",
    },
    "&::-webkit-outside-spin-button": {
      display: "none",
    },
    "&::-webkit-calendar-picker-indicator": {
      display: "none",
    },
    "&::placeholder": {
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        lineHeight: "19px",
      },
      fontSize: 12,
      lineHeight: "16px",
      color: "#C4C4C4",
      opacity: 1,
    },
    height: "32px",
    "-webkit-appearance": "none",
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "-moz-appearance": "textfield !important",
    padding: "0px 6px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  },
  root: (props) => ({
    padding: 0,
    //height: "36px",
    height: props.multiple ? "auto" : "32px",
    "& .MuiAutocomplete-inputRoot": {
      //height: "36px",
      height: props.multiple ? "auto" : "32px",
      padding: 0,
    },
    "& .MuiInputBase-input": {
      padding: "8px 12px",
      "-moz-appearance": "textfield",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      [theme.breakpoints.up("md")]: {
        fontSize: 14,
        lineHeight: "13px",
      },
      fontSize: 14,
      lineHeight: "16px",
      color: props.whiteLabel ? "white" : "black",
      transform: "translate(4px, -20px) scale(1)",
      marginBottom: "4px",
    },
    "& .MuiInputLabel-outlined": {
      fontSize: 14,
      transform: "translate(10px, 10px) scale(1)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "90%",
      paddingBottom: 1
    },
    "& label.Mui-focused": {
      color: "#c4c4c4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#c4c4c4",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#c4c4c4",
        borderWidth: "1px",
        top: 0,
      },
      "& legend": {
        display: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#c4c4c4",
        borderWidth: "1px",
        top: 0,
      },
      "&.Mui-focused legend": {
        display: "none",
      },
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
  }),
  section_with_title: {
    fontSize: 14,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  section_with_title_title: {
    marginBottom: "5px",
    fontSize: 14,
    width: "max-content",
  },
  option: {
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      lineHeight: "19px",
    },
    fontSize: 12,
    lineHeight: "16px",
  },
}));

function AutocompleteWithLabel(props) {
  const {
    placeholder = "",
    value = "",
    setter = () => {
      return true;
    },
    onInputChange = () => {
      return true;
    },
    getLabel = (x) => x,
    getOption = (x, v) => x === v,
    label = "",
    options = null,
    disabled = false,
    freeSolo = false,
    whiteLabel = false,
    multiple = false,
    customClass = "",
    show = "notApply",
    selectorFunction = () => {
      return true;
    },
    load = false,
    onBlur = () => {
      return true;
    },
    filterOptions = null,
    groupBy = null,
    showIcon = "notApply",
    required = false,
    border,
    wrong = false,
    renderOption,
    useDefaultIcon = false,
    onOpen,
    moveLabel = false,
    customClassLabel,
    customClassWrapper,
    getCustomClassOptions = (x) => "",
    getOptionDisabled = () => {
      return false;
    },
    disableClearable = false,
    maxLength = false,
    debug = false,
    type = "text",
    escapeBiggerThan = false,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ multiple: multiple, whiteLabel: whiteLabel });
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (debug) {
      console.log("VALUE: ", value);
    }
    if (!value) {
      setInputValue("");
    }
  }, [value]);

  function handleMaxLength(value) {
    if (typeof value === "string") {
      if (value.length <= maxLength) {
        setInputValue(value);
      }
    } else {
      if (value.length <= maxLength) {
        setInputValue(value);
      }
    }
  }

  function handleBiggerThanNumber(value) {
    setInputValue(value.replace(/[^0-9>]/g, ""));
  }

  function renderAutcomplete() {
    return (
      <Autocomplete
        renderOption={renderOption}
        autoSelect={freeSolo}
        freeSolo={freeSolo}
        className={`${classes.inputBase} ${customClass}`}
        options={options}
        popupIcon={
          useDefaultIcon ? (
            <div title={t(`icons_tooltips.expand`)}>
              <Down color="yellow" />
            </div>
          ) : (
            <div title={t(`icons_tooltips.expand`)}>
              <Down color="primary" />
            </div>
          )
        }
        renderOption={(option) => (
          <Typography className={`${classes.option} ${getCustomClassOptions(option)}`}>
            {option.name || option.nombre}
          </Typography>
        )}
        closeIcon={<div />}
        value={value}
        inputValue={inputValue}
        onChange={(e, value) => {
          setter(value);
        }}
        onInputChange={(e, value) =>
          maxLength
            ? handleMaxLength(value)
            : escapeBiggerThan
            ? handleBiggerThanNumber(value)
            : setInputValue(value)
        }
        noOptionsText={t("basicData.no_options")}
        multiple={multiple}
        disabled={disabled}
        autoHighlight={true}
        blurOnSelect={true}
        onBlur={onBlur}
        getOptionLabel={getLabel}
        getOptionSelected={getOption}
        loading={load}
        onOpen={onOpen}
        loadingText={
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img src="/images/loading.gif" width={24} height={24}/>
          </div>
        }
        ChipProps={{
          classes: {
            root: classes.customChip,
            deleteIcon: classes.customChip_deleteIcon,
            label: classes.customChip_label,
          },
        }}
        classes={{ listbox: classes.paper }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            onChange={(e) => selectorFunction(e.target.value)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              autoComplete: "disabled",
              classes: {
                input: classes.input_autocomplete,
                root: `${classes.input_autocomplete} ${customClassLabel}`,
              },
            }}
            classes={{ root: `${classes.root}` }}
            label={moveLabel ? label : null}
            error={wrong}
            required={required}
            type={type}
            onKeyDown={(evt) =>
              type === "number"
                ? (evt.key === "e" ||
                    evt.key === "-" ||
                    evt.key === "." ||
                    evt.key === "," ||
                    evt.key === "+") &&
                  evt.preventDefault()
                : null
            }
          />
        )}
        {...(filterOptions ? { filterOptions: filterOptions } : {})}
        {...(groupBy ? { groupBy: groupBy } : {})}
        {...(show !== "notApply" ? { open: show } : {})}
        {...(showIcon !== "notApply" ? { forcePopupIcon: showIcon } : {})}
        getOptionDisabled={getOptionDisabled}
        disableClearable={disableClearable}
      />
    );
  }

  function renderAutcompleteLabel() {
    return (
      <div className={classes.section_with_title}>
        <div className={classes.section_with_title_title}>{label}</div>
        <Autocomplete
          renderOption={renderOption}
          autoSelect={freeSolo}
          freeSolo={freeSolo}
          className={classes.inputBase}
          options={options}
          renderOption={(option) => (
            <Typography className={classes.option}>{option}</Typography>
          )}
          popupIcon={
            useDefaultIcon ? (
              <div title={t(`icons_tooltips.expand`)}>
                <Down color="yellow" />
              </div>
            ) : (
              <div title={t(`icons_tooltips.expand`)}>
                <Down color="primary" />
              </div>
            )
          }
          closeIcon={<div />}
          value={value}
          onChange={(e, value) => setter(value)}
          onInputChange={(e, value) => onInputChange(value)}
          multiple={multiple}
          disabled={disabled}
          autoHighlight={true}
          blurOnSelect={true}
          onBlur={onBlur}
          getOptionLabel={getLabel}
          getOptionSelected={getOption}
          loading={load}
          onOpen={onOpen}
          loadingText={
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src="/images/loading.gif" width={24} height={24}/>
            </div>
          }
          ChipProps={{
            classes: {
              root: classes.customChip,
              deleteIcon: classes.customChip_deleteIcon,
              label: classes.customChip_label,
            },
          }}
          classes={{ listbox: classes.paper }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
              onChange={(e) => selectorFunction(e.target.value)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                autoComplete: "disabled",
                classes: {
                  input: classes.input_autocomplete,
                  root: `${classes.input_autocomplete} ${customClassLabel}`,
                },
              }}
              classes={{ root: `${classes.root}` }}
              error={wrong}
              required={required}
            />
          )}
          {...(filterOptions ? { filterOptions: filterOptions } : {})}
          {...(groupBy ? { groupBy: groupBy } : {})}
          {...(show !== "notApply" ? { open: show } : {})}
          {...(showIcon !== "notApply" ? { forcePopupIcon: showIcon } : {})}
        />
      </div>
    );
  }

  return (
    <Wrapper
      label={moveLabel ? null : label}
      customClass={customClass}
      customClass={customClassWrapper}
      type="autocomplete"
      disabled={disabled}
      required={required}
      border={border}
      wrong={wrong}
      moveLabel={moveLabel}
      multiple={multiple}
      hasValue={value}
    >
      {renderAutcomplete()}
    </Wrapper>
  );
}

export default AutocompleteWithLabel;
