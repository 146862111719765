import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Camera from "../assets/icons/camera";
import { base64ToArrayBuffer, validateFile } from "../utils/functions";
import { withSnackbar } from "notistack";
import { downloadDocumentInform } from "../api/maestro";
import {
  uploadCandidateDocuments,
  deleteCandidateDocuments,
} from "../api/candidato";
import { returnLangId, returnMimeData } from "../utils/functions";
import i18next from "../i18n.js";
import Snackbar from "../components/customSnackBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AlertDialog from "./alertDialog";

const useStyles = makeStyles((theme) => ({
  profile_photo_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 156,
    width: 156,
    borderRadius: 6,
    background: "linear-gradient(180deg, #EEEEEE 0%, #D8D8D8 100%)",
    boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
    [theme.breakpoints.down("sm")]: {
      height: 128,
      width: 128,
    },
  },
  editable_container: {
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  photo_container: {
    position: "relative",
    height: "100%",
    width: "100%",
  },
  photo: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: 6,
    objectFit: "cover",
  },
  hidden_input: {
    display: "none",
  },
}));

function ProfilePicture(props) {
  const { t } = useTranslation();
  const { isEditAvailable = false, setter = null, propsMargin = 0 } = props;
  const classes = useStyles();

  const [snackbarText, setSnackbarText] = useState(null);
  const [anchorElement, setAnchorElement] = useState(null);
  const [docImg, setDocImg] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [loadingPic, setLoadingPic] = useState(false);
  const [refPicture, setRefPicture] = useState(null);
  const [idFotografia, setIdFotografia] = useState(null);

  const [file, setFile] = useState(null);
  const [blob, setBlob] = useState(null);
  const [doc, setDoc] = useState(null);
  const inputRef = useRef(null);
  const { state, actions } = props;

  useEffect(() => {
    if (state) {
      if (state.idPhotography) {
        setIdFotografia(state.idPhotography);
      }
    }
  }, [state]);

  useEffect(() => {
    if (!refPicture && idFotografia) {
      getProfilePic();
    }
  }, [idFotografia]);

  useEffect(() => {
    if (doc && blob) {
      uploadProfilePic();
    }
  }, [doc, blob]);

  useEffect(() => {
    if (file) {
      handleFiles();
    }
  }, [file]);

  function handleSelectFile(files) {
    if (files[0]) {
      if (validateFile(files[0]) && files[0].name.length <= 80) {
        setFile(files[0]);
      } else {
        if (files[0].size === 0) {
          props.enqueueSnackbar(t("error_docs.error_not_valid2"), {
            variant: "error",
          });
        } else if (files[0].name.length > 80) {
          props.enqueueSnackbar(t("error_docs.error_not_valid3"), {
            variant: "error",
          });
        } else {
          props.enqueueSnackbar(t("error_docs.error_not_valid"), {
            variant: "error",
          });
        }
      }
    }
  }

  async function getProfilePic() {
    setLoadingPic(true);
    const response = await downloadDocumentInform(idFotografia);
    if (response.status === 200 || response.status === 201) {
      const result = await response.json();
      const obj_url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(result)], {
          type: `application/image/*`,
        })
      );
      setLoadingPic(false);
      setDocImg(idFotografia);
      setRefPicture(obj_url);
      setter && setter(obj_url);
    } else {
      setDocImg(null);
      setLoadingPic(false);
    }
  }

  function handleFiles() {
    setLoadingPic(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBlob(reader.result.substring(reader.result.indexOf(",") + 1));
      setDoc(file);
    };
  }

  function handleOpenMenu(event) {
    setAnchorElement(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorElement(null);
  }

  function handleMenuDelete() {
    handleCloseMenu();
    setOpenConfirm(false);
    deleteProfilePic();
  }

  function handleMenuEditPic() {
    handleCloseMenu();
    inputRef.current.click();
  }

  async function uploadProfilePic() {
    const data = {
      idTipoDocumento: 16,
      documentos: [
        {
          nombre: doc.name,
          fichero: blob,
        },
      ],
    };
    const response = await uploadCandidateDocuments(
      data,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      const result = await response.json();
      const obj_url = URL.createObjectURL(
        new Blob([base64ToArrayBuffer(blob)], {
          type: `application/${returnMimeData(doc.name)}`,
        })
      );
      setRefPicture(obj_url);
      setter && setter(obj_url);
      setDocImg(result?.documentos[0]?.idDocumento);
      actions.setCandidateIdPhotography(result?.documentos[0]?.idDocumento);
      setFile(null);
      setBlob(null);
      setDoc(null);
      setLoadingPic(false);
      setIsSnackBarOpen(true);
      setSnackbarText(t("profile_picture.succesful_saved_image"));
    } else {
      props.enqueueSnackbar(t("candidateDashboard.error_loading_img"), {
        variant: "error",
      });
      setBlob(null);
      setDoc(null);
      setLoadingPic(false);
    }
  }

  async function deleteProfilePic() {
    setLoadingPic(true);
    const response = await deleteCandidateDocuments(
      docImg,
      returnLangId(i18next.languages[0])
    );
    if (response.status === 200) {
      actions.clearCandidateIdPhotography();
      setFile(null);
      setDocImg(null);
      setRefPicture(null);
      setter && setter(null);
      setLoadingPic(false);
      setIsSnackBarOpen(true);
      setSnackbarText(t("profile_picture.succesful_delete_image"));
    } else {
      props.enqueueSnackbar(t("profile_picture.error_delete"), {
        variant: "error",
      });
      setLoadingPic(false);
    }
  }

  return (
    <>
      <Snackbar
        text={snackbarText}
        isSnackBarOpen={isSnackBarOpen}
        handleIsSnackBarOpen={setIsSnackBarOpen}
      />
      <Menu
        id="profile-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {docImg ? (
          <>
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                setOpenConfirm(true);
              }}
            >
              {t("profile_picture.profile_pic_menu1")}
            </MenuItem>
            <MenuItem onClick={handleMenuEditPic}>
              {t("profile_picture.profile_pic_menu2")}
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={handleMenuEditPic}>
            {t("profile_picture.profile_pic_menu3")}
          </MenuItem>
        )}
      </Menu>
      <div
        className={`${classes.profile_photo_container} ${
          isEditAvailable && classes.editable_container
        }`}
        onClick={(event) => {
          isEditAvailable && !loadingPic && handleOpenMenu(event);
        }}
        style={{ margin: propsMargin }}
      >
        {loadingPic ? (
          <img src="/images/loading.gif" width={64} height={64}/>
        ) : refPicture ? (
          <div className={classes.photo_container}>
            <img src={refPicture} className={classes.photo} alt="" />
            {isEditAvailable && (
              <Camera
                title={t("icons_tooltips.upload_picture")}
                shrink={true}
              />
            )}
          </div>
        ) : (
          <Camera title={t("icons_tooltips.upload_picture")} />
        )}
      </div>
      <input
        type="file"
        className={classes.hidden_input}
        ref={inputRef}
        accept=".jpg, .jpeg, .png"
        onChange={(event) => handleSelectFile(event.target.files)}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      <AlertDialog
        title={t("profile_picture.profile_pic_alert_confirm_title")}
        open={openConfirm}
        handleClose={() => {
          setOpenConfirm(false);
        }}
        handleAccept={() => {
          handleMenuDelete();
          setOpenConfirm(false);
        }}
      />
    </>
  );
}

export default withSnackbar(ProfilePicture);
