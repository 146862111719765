import React from "react";
import { useTranslation } from "react-i18next";

function PerfilDigitalTwitter(props) {
    const { t } = useTranslation();
    const { title = t("icons_tooltips.twitter")} = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="x" transform="translate(963 -12014)">
                <rect id="Rectángulo_2130" data-name="Rectángulo 2130" width="24" height="24" transform="translate(-963 12014)"/>
                <path id="Icon_simple-x" data-name="Icon simple-x" d="M13.073,1.153h2.545L10.058,7.509,16.6,16.157H11.478L7.466,10.912,2.875,16.157H.328l5.948-6.8L0,1.154H5.253L8.879,5.948ZM12.18,14.634h1.41L4.486,2.6H2.973Z" transform="translate(-959.3 12017.345)" fill="#fff"/>
            </g>
        </svg>
    );
}

export default PerfilDigitalTwitter;