import React, { useCallback, useEffect,useState } from 'react';
import { Typography } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { makeStyles } from "@material-ui/core/styles";
import Clip from "../assets/icons/clip";
import { useTranslation } from "react-i18next";
import { base64ToArrayBuffer, handleErrorResponse, returnLangId, returnMimeData, validateFile } from '../utils/functions';
import DownloadDocument from '../assets/icons/downloadDocument';
import Trash from '../assets/icons/trash';
import { downloadDocumentInform } from '../api/maestro';
import { deleteCandidateDocuments, uploadCandidateDocuments } from '../api/candidato';
import i18next from 'i18next';

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #d5d5d5",
    borderRadius: "4px",
    padding: "4px 14px",
    cursor: "pointer",
    overflow: "hidden",
  },
  line: {
    overflow: "hidden",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "underline",
    color: "#5D5A5A",
  },
}));

function DragAndDropInput(props) {
  const {
    accept = "",
    enqueueSnackbar,
    texto = "Arrastre aquí o seleccione su archivo",
    files,
    setFiles,
    idTipoDocumento
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const [currentFile, setCurrentFile] = useState({});
  const [uploadedDocuments, setUploadedDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const errorsDocument = [
    {
      hasAnError: (doc) => doc && doc.size === 0,
      errorMessage: () => enqueueSnackbar(t("error_docs.error_not_valid2"), { variant: "error" })
    },
    {
      hasAnError: (doc) => doc && doc.name.length > 80,
      errorMessage: () => enqueueSnackbar(t("error_docs.error_not_valid3"), { variant: "error" })
    },
    {
      hasAnError: (doc) => !doc,
      errorMessage: () => enqueueSnackbar(t("error_docs.error_not_valid4"), { variant: "error" })
    },
  ];

  useEffect(() => {
    const hasCurrentFile = currentFile && Object.keys(currentFile).length > 0;
    if (hasCurrentFile) {
      guardarDocumentos();
    }
  }, [currentFile]);

  const borrarDocumentos = () => {
    setFiles({});
    setCurrentFile({});
    setUploadedDocuments({});
  };

  const generarDoc = (fichero, nombre) => {
    const obj_url1 = URL.createObjectURL(
      new Blob([base64ToArrayBuffer(fichero)], {
        type: returnMimeData(nombre),
      })
    );
    let fileLink1 = document.createElement("a");
    fileLink1.href = obj_url1;
    fileLink1.download = nombre;
    fileLink1.click();
  };

  const handleDescargarDocumento = (async () => {
    setLoading(true);
    downloadDocumentInform(uploadedDocuments[0].idDocumento).then((response) => {
      if (response.status === 200) {
        response.json().then((result) => {
          generarDoc(result, uploadedDocuments[0].nombre);
          setLoading(false);
        });
      } else {
        handleErrorResponse(enqueueSnackbar, response, response.errorMessage);
        setLoading(false);
      }
    });
  });

  const guardarDocumentos = useCallback(() => {
    setLoading(true);
    let data = { idTipoDocumento: idTipoDocumento, documentos: [currentFile] };
    uploadCandidateDocuments(data, returnLangId(i18next.languages[0])).then((response) => {
      if (response.status === 200) {
        response.json().then((result) => {
          setCurrentFile({});
          const newDocument = result.documentos.map((uploadedDoc) => ({
            idDocumento: uploadedDoc.idDocumento,
            nombre: uploadedDoc.nombre,
            idTipoDocumento: result.idTipoDocumento,
          }));
          setUploadedDocuments(newDocument);
          setLoading(false);
        });
      } else {
        handleErrorResponse(enqueueSnackbar, response, response.errorMessage);
        setFiles([]);
        setCurrentFile({});
        setLoading(false);
      }
    });
  }, [currentFile, files]);

  const handleFileChange = (newFiles) => {
    if (validateFile(newFiles[0]) && newFiles[0].name.length <= 80) {
      const reader = new FileReader();
      reader.readAsDataURL(newFiles[0]);
      reader.onload = function () {
        let name = newFiles[0].name.split(".");
        const data = {
          nombre: newFiles[0].name.replace(
            "." + name[name.length - 1],
            "_a." + name[name.length - 1]
          ),
          fichero: reader.result.substring(reader.result.indexOf(",") + 1),
        };
        setFiles(data);
        setCurrentFile(data);
      }
    } else {
      errorsDocument.forEach((value) => (
        value.hasAnError(newFiles[0]) ?
          value.errorMessage(newFiles[0]) :
          enqueueSnackbar(t("error_docs.error_not_valid"), { variant: "error" })
      ));
    }
  };

  const handleBorrarDocumentos = useCallback(async () => {
    setLoading(true);
    const response = await deleteCandidateDocuments(uploadedDocuments[0]?.idDocumento,
      returnLangId(i18next.languages[0]));
    if (response.status === 200) {
      borrarDocumentos();
    } else {
      handleErrorResponse(enqueueSnackbar, response, response.errorMessage);
    }
    setLoading(false);
  }, [uploadedDocuments]);

  const renderFilledInput = () => {
    return (
      <>
        <Typography variant="body1" className={classes.nombre_documentos}>
          {<p>{files.nombre}</p>}
        </Typography>
        <div style={{ marginLeft: "10px" }}>
          <Trash colorFill="#777" onClick={handleBorrarDocumentos} title={t(`icons_tooltips.delete`)} />
        </div>
        <div style={{ marginLeft: "10px" }} onClick={handleDescargarDocumento}>
          <DownloadDocument title={t("icons_tooltips.download")} />
        </div>
      </>
    );
  };

  const renderUploadInput = () => {
    return (
      <Dropzone
        onDrop={handleFileChange}
        accept={accept}
      >
        {({ getRootProps, getInputProps }) => (
          <div className={classes.container}>
            <div {...getRootProps()} className={classes.line}>
              <input {...getInputProps()} />
              <Clip size={"20px"} />
              {texto}
            </div>
          </div>
        )}
      </Dropzone>
    );
  }

  function returnBodyComponent() {
    return (
      Object.keys(files).length === 2
        ?
        <div className={classes.container}>
          {renderFilledInput()}
        </div>
        :
        renderUploadInput()
    );
  }

  function returnLoading() {
    return (
      <div className={classes.container}>
        <img alt="/images/loading.gif" width={24} height={24}/>
      </div>
    );
  }

  return (
    <>
      {loading ? returnLoading() : returnBodyComponent()}
    </>
  );
}

export default DragAndDropInput